import features from '@/helpers/features';
import { getPersonIdFromSessionStorage } from '@/helpers/auth';
import { useGetProgramsQuery } from '@/store/queries/programs';
import { useGetLatestWorkflowQuery } from '@/store/queries/workflows';
import { useGetCoursesBySourceIdsQuery, useGetMembershipSourceIdsQuery } from '@/store/queries/courses';
import { getCurrentCoursesByRange, getFutureCoursesByRange } from '@/myphoenix/utils/course-functions';
import { isEnrollmentApplicationActive } from '@/myphoenix/utils/enrollment-functions';
import AlumniNAEGuidanceMessage from './AlumniNAEGuidanceMessage';

function AlumniNAEGuidance({ children }: any) {
  // alumni re-enroll feature flag enabled
  const alumniReEnrollEnabled = features.alumniReenrollEnabled;

  // has a primary program
  const { data: { primaryProgram } = {}, isFetching: programsFetching } = useGetProgramsQuery(
    { personId: getPersonIdFromSessionStorage() },
  );

  // is an alumni
  const enrollmentStatus = primaryProgram?.statuses
    ? primaryProgram?.statuses?.find((status: any) => status?.statusType === 'EnrollmentStatus')
    : undefined;

  const {
    data: { applicationStartDate, applicationType } = {},
  } = useGetLatestWorkflowQuery({
    personId: getPersonIdFromSessionStorage(),
  });

  const alumni = enrollmentStatus !== null && enrollmentStatus?.status === 'G';
  const alumniApplicationStudent = alumni && applicationStartDate !== null
    && isEnrollmentApplicationActive(applicationStartDate) && applicationType === 'ALUMNI';

  // has no future or current courses
  const { data: { sourceIds } = {} } = useGetMembershipSourceIdsQuery(
    { personId: getPersonIdFromSessionStorage() },
  );
  const { data: courses = [] } = useGetCoursesBySourceIdsQuery({ sourceIds });
  const currentCourses = getCurrentCoursesByRange(courses);
  const futureCourses = getFutureCoursesByRange(courses);
  const hasNoFutureOrCurrentCourses = !currentCourses.length && !futureCourses.length;

  // Show Tooltip
  const showAlumniNAEGuidance = alumniReEnrollEnabled
    && hasNoFutureOrCurrentCourses
    && alumniApplicationStudent
    && !sessionStorage.getItem('dismissAlumniNAEGuidanceAlert')
    && !programsFetching;

  const onDismiss = () => {
    sessionStorage.setItem('dismissAlumniNAEGuidanceAlert', 'true');
  };

  return (
    <AlumniNAEGuidanceMessage
      program={primaryProgram?.description}
      isOpen={showAlumniNAEGuidance}
      onDismiss={onDismiss}
    >
      {children}
    </AlumniNAEGuidanceMessage>
  );
}

export default AlumniNAEGuidance;
