import DegreeTile from '@/components/molecules/degree-tile';
import { CourseInfoData } from '@/types/courseInfoData';
import { CourseInfoScenarioInterface } from '@/types/courseInfoScenarios';

function GraduatedScenario(): CourseInfoScenarioInterface {
  return {
    shouldShow: ({ enrollmentStatus, currentCourses, futureCoursesInNext7Days }: CourseInfoData) => enrollmentStatus?.status === 'C'
      && currentCourses.length === 0 && futureCoursesInNext7Days.length === 0,
    Component: (
      { courseInfoData: { parentComponentName, primaryProgram } }
      : { courseInfoData: CourseInfoData },
    ) => (
      <DegreeTile
        parentComponentName={parentComponentName}
        primaryProgramName={primaryProgram.name}
      />
    ),
  };
}

export default GraduatedScenario;
