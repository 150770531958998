import {
  styled, Card, Box, IconButton, Grid,
} from '@mui/material';

export const StyledCard = styled(Card)(({ theme }) => ({
  padding: theme.spacing(2),
}));
export const TitleContainer = styled(Box)(({ theme }) => ({
  paddingBottom: theme.spacing(3.2),
}));

export const TextGrid = styled(Grid)(({ theme }) => ({
  maxWidth: theme.spacing(19.8),
  [theme.breakpoints.up('sm')]: {
    maxWidth: '100%',
  },
}));

export const DonutBox = styled(Box)(({ theme }) => ({
  minWidth: theme.spacing(15),
  minHeight: theme.spacing(15),
  paddingBottom: theme.spacing(2.2),
  [theme.breakpoints.up('sm')]: {
    marginTop: theme.spacing(-4.5),
    paddingBottom: 0,
  },
}));

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
  padding: 0,
  marginTop: theme.spacing(-0.5),
}));
