import React from 'react';
import { Typography, Box } from '@mui/material';
import ArrowLink from '@/components/atoms/arrow-link';
import ErrorMessage from '@/components/atoms/error-message';
import { visuallyHidden } from '@mui/utils';
import { MarginWrapper, StyledGpa, Wrapper } from './ProgramProgressGPA.styles';

const DEFAULT_GPA = '---';

type ProgramProgressGPAProps = {
  calculateLabel?: string,
  calculateLink?: string,
  showCalculateLink?: boolean,
  error?: boolean,
  errorMessage?: string,
  errorLinkText?: string,
  gpa?: string,
  heading?: string,
};

function formatGPA(gpa: string) {
  const numericGpa = Number(gpa);
  return Number.isNaN(numericGpa) ? DEFAULT_GPA : numericGpa.toFixed(2);
}

function ProgramProgressGPA({
  calculateLabel = 'Calculate your GPA',
  calculateLink,
  showCalculateLink = true,
  error,
  errorMessage,
  errorLinkText,
  gpa = DEFAULT_GPA,
  heading = 'Your current GPA',
}: ProgramProgressGPAProps) {
  const componentName = 'ProgramProgressGPA';
  const formattedGpa = formatGPA(gpa);
  return (
    <MarginWrapper>
      <Typography variant="body4" component="h3" fontWeight="700">{heading}</Typography>
      <Wrapper>
        {error ? (
          <Box mt={1.3}>
            <ErrorMessage
              errorMessage={errorMessage}
              errorLinkText={errorLinkText}
              errorComponent="ProgramProgressGPA"
            />
          </Box>
        ) : (
          <>
            <StyledGpa aria-hidden="true" variant="body5">{formattedGpa}</StyledGpa>
            <Typography sx={visuallyHidden}>
              {gpa === DEFAULT_GPA ? '0' : gpa}
            </Typography>
            {(calculateLink && showCalculateLink) && (
              <ArrowLink
                link={calculateLink}
                aria-label={calculateLabel}
                clickEvent={{ componentName, properties: [{ gpa }] }}
              >
                {calculateLabel}
              </ArrowLink>
            )}
          </>
        )}
      </Wrapper>
    </MarginWrapper>
  );
}

export default ProgramProgressGPA;
