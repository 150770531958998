import { CourseInfoData } from '@/types/courseInfoData';
import { CourseInfoScenarioInterface } from '@/types/courseInfoScenarios';
import PrepareForClassTile from '@/components/molecules/prepare-for-class-tile';
import { reEnrollStudent, isReEnrollI, enrollmentApplicationSubmitted, getEditApplicationLink } from '@/myphoenix/utils/enrollment-functions';
import { toDateTimeFromMs } from '@/myphoenix/utils/date-time-functions';
import features from '@/helpers/features';
import makeAbsoluteUrl from '@/helpers/absolute-url';

function PrepareForClassReenrollScenario(): CourseInfoScenarioInterface {
  return {
    shouldShow: (courseInfoData: CourseInfoData) => features?.reenrollEnabled
      && !courseInfoData?.currentCourses?.length
      && !courseInfoData?.futureCoursesInNext7Days?.length
      && reEnrollStudent(
        courseInfoData?.enrollmentStatus,
        courseInfoData?.admissionStatus,
        courseInfoData?.enrollmentApp?.startTime,
      ) && !isReEnrollI(courseInfoData?.enrollmentApp?.type)
      && enrollmentApplicationSubmitted(courseInfoData?.enrollmentApp?.submissionStatus)
      && (courseInfoData?.hasCompletedEnrollmentTasks
        || courseInfoData?.hasIncompleteEnrollmentTasks),
    Component: ({ courseInfoData }: { courseInfoData: CourseInfoData }) => {
      const {
        enrollmentApp: {
          program: { description },
          reviewStatus, submissionStatus, submittedTime, version, ID, type,
        },
        enrollmentRep: { firstName, lastName, phoneNumber, email },
        hasCompletedEnrollmentTasks,
        hasIncompleteEnrollmentTasks,
        primaryProgram: { isCBEDA },
      } = courseInfoData;
      return (
        <PrepareForClassTile
          id="ReEnroll"
          programTitle={description}
          contactFirstName={firstName}
          contactLastName={lastName}
          contactPhoneNumber={phoneNumber}
          contactEmail={email}
          applicationReviewStatus={reviewStatus}
          applicationSubmissionStatus={submissionStatus}
          applicationSubmittedAt={
            Number.isInteger(submittedTime)
              ? toDateTimeFromMs(submittedTime).toISO()
              : null
          }
          hasCompletedEnrollmentTasks={hasCompletedEnrollmentTasks}
          hasIncompleteEnrollmentTasks={hasIncompleteEnrollmentTasks}
          isCBEDA={isCBEDA}
          editApplicationLink={getEditApplicationLink(Number(version), reviewStatus)}
          completeTasksURL={makeAbsoluteUrl('/message-center.html')}
          applicationId={ID}
          applicationType={type}
        />
      );
    },
  };
}

export default PrepareForClassReenrollScenario;
