import { useState } from 'react';
import type { CourseInfoData } from '@/types/courseInfoData';
import { CourseInfoScenarioInterface } from '@/types/courseInfoScenarios';
import UpcomingTile from '@/components/molecules/upcoming-tile';
import DroppedSingleTile from '@/components/molecules/dropped-tile';
import { Grid, Theme, useMediaQuery } from '@mui/material';
import makeAbsoluteUrl from '@/helpers/absolute-url';
import { getScheduledCourses, getEnrolledCourses, getDroppedCourses } from '@/myphoenix/utils/course-functions';
import InfoCard from '@/components/molecules/info-card';
import CourseList from '@/components/molecules/course-list';

function UpcomingSmallScenario(): CourseInfoScenarioInterface {
  return {
    shouldShow: (courseInfoData: CourseInfoData) => (
      (!!getEnrolledCourses(courseInfoData.currentCourses).length
      || !!getDroppedCourses(courseInfoData.currentCourses).length)
      && !!getScheduledCourses(courseInfoData.futureCoursesInNext7Days).length
    ),
    Component({ courseInfoData }: { courseInfoData: CourseInfoData }) {
      const {
        parentComponentName,
        futureCoursesInNext7Days,
        currentCourses,
        primaryProgram,
        enrollmentRep,
        academicCounselor,
        primaryProgram: {
          name: programName = '',
          isCBEDA,
        },
        instructorsForMemberships,
        canUserViewSkills,
        courseSkills,
      } = courseInfoData;

      const [selectedCourseIndex, setSelectedCourseIndex] = useState(0);
      const scheduledCourses = getScheduledCourses(futureCoursesInNext7Days);
      const enrolledCurrentCourses = getEnrolledCourses(currentCourses);
      const droppedCurrentCourses = getDroppedCourses(currentCourses);
      const enrolledAndDroppedCourses = droppedCurrentCourses?.length
        ? [...enrolledCurrentCourses, ...droppedCurrentCourses] : enrolledCurrentCourses;

      const storageKey = 'UpcomingCourse';
      const sessionIndex = Number(sessionStorage.getItem(storageKey) || undefined);
      const validSessionIndex = !Number.isNaN(sessionIndex)
        && scheduledCourses?.[sessionIndex] !== undefined;
      const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
      const initialIndex = validSessionIndex ? sessionIndex : 0;
      const [currentCourseIndex, setCurrentCourseIndex] = useState(initialIndex);

      const setCurrentCourseIndexAndStorageKey = (newIndex: number) => {
        if (scheduledCourses?.[newIndex] !== undefined) {
          sessionStorage.setItem(storageKey, `${newIndex}`);
          setCurrentCourseIndex(newIndex);
        }
      };

      const counselorName = primaryProgram.type === 'Program'
        ? `${enrollmentRep.firstName} ${enrollmentRep.lastName}`
        : `${academicCounselor.firstName} ${academicCounselor.lastName}`;

      const counselorPhoneNumber = primaryProgram.type === 'Program'
        ? enrollmentRep.phoneNumber : academicCounselor.phoneNumber;

      const hasSkills = courseSkills
        && Object.values(courseSkills)?.some((skillList) => skillList?.length > 0);

      return (
        <Grid
          container
          spacing={2}
        >
          <Grid item xs={12} sm={7} md={7}>
            {droppedCurrentCourses && !enrolledCurrentCourses.length && (
            <DroppedSingleTile
              parentComponentName={parentComponentName}
              small={!!droppedCurrentCourses.length && !!futureCoursesInNext7Days.length}
              courses={currentCourses}
              counselor={counselorName}
              phone={counselorPhoneNumber}
              primaryProgramName={primaryProgram.name}
            />
            )}
            {(enrolledCurrentCourses.length || droppedCurrentCourses.length > 1) && (
              isMobile ? (
                enrolledAndDroppedCourses?.map((course, index) => (
                  <Grid item key={course.id}>
                    <InfoCard
                      parentComponentName={parentComponentName}
                      heading={programName}
                      tileType={`current-course-${index}`}
                      content={(
                        <CourseList
                          courses={[course]}
                          courseSkills={courseSkills}
                          selectedCourseIndex={selectedCourseIndex}
                          setSelectedCourseIndex={setSelectedCourseIndex}
                          userHasSkillsClass={canUserViewSkills && hasSkills}
                          instructors={instructorsForMemberships}
                          isCBEDA={isCBEDA}
                          primaryButtonText="Go to class"
                        />
                      )}
                      applyMarginBottom={index !== enrolledAndDroppedCourses.length - 1}
                    />
                  </Grid>
                ))
              )
                : (
                  <InfoCard
                    parentComponentName={parentComponentName}
                    heading={
                      enrolledAndDroppedCourses.length > 1
                        ? `Class ${selectedCourseIndex + 1} of ${enrolledAndDroppedCourses.length}`
                        : programName
                      }
                    tileType="current-course"
                    content={(
                      <CourseList
                        courses={enrolledAndDroppedCourses}
                        courseSkills={courseSkills}
                        selectedCourseIndex={selectedCourseIndex}
                        setSelectedCourseIndex={setSelectedCourseIndex}
                        userHasSkillsClass={canUserViewSkills && hasSkills}
                        instructors={instructorsForMemberships}
                        isCBEDA={isCBEDA}
                        fullWidth
                        primaryButtonText="Go to class"
                      />
                    )}
                  />
                )
            )}
          </Grid>
          <Grid item xs={12} sm={5} md={5}>
            <UpcomingTile
              upcomingClasses={scheduledCourses}
              link={makeAbsoluteUrl('/academic-plan.html')}
              linkLabelBeforeStartDate="Begins"
              linkLabelNoStartDate="See more"
              linkLabelSROnly="Redirects to Academic Plan"
              isCBEDA={isCBEDA}
              selectedCourseIndex={currentCourseIndex}
              setSelectedCourseIndex={setCurrentCourseIndexAndStorageKey}
            />
          </Grid>
        </Grid>
      );
    },
  };
}

export default UpcomingSmallScenario;
