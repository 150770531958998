import { useEffect, useMemo, useState } from 'react';
import {
  COURSE_INFO_START_MARK_NAME,
  COURSE_INFO_RENDERED_MARK_NAME,
} from '@/myphoenix/utils/event-functions';
import { addPerformanceMark } from '@/helpers/window-functions';
import { toDateTime } from '@/myphoenix/utils/date-time-functions';
import { getCurrentCourses,
  getDroppedCourses,
  getEnrolledCourses,
  getFutureCourses,
  getPastCourses,
  getPreviousCourses,
  aggregateCourseSkills } from '@/myphoenix/utils/course-functions';
import {
  useGetProgramsQuery,
  useGetSkillsByProgramIdQuery,
  useGetProgramProgressDataByProgramIdQuery,
  useGetProgramInfoByProgramOfferingIdQuery,
} from '@/store/queries/programs';
import { useGetStudentLoaQuery, useGetCourseOutcomesQuery } from '@/store/queries/student';
import { getPersonIdFromSessionStorage } from '@/helpers/auth';
import type { Course, CourseInfoData, Instructor } from '@/types/courseInfoData';
import { CourseInfoScenarioInterface } from '@/types/courseInfoScenarios';
import { useGetUniversityContactsQuery } from '@/store/queries/contacts';
import { useGetLatestWorkflowQuery } from '@/store/queries/workflows';
import { useGetTasksQuery } from '@/store/queries/notifications';
import { getClassroomClassUrl, getClassroomKey } from '@/myphoenix/utils/classroom-functions';
import {
  useGetCoursesByMembershipsQuery,
  useGetInstructorsBySourceIdsQuery,
} from '@/store/queries/courses';
import { useGetApplicationInfoByIdQuery } from '@/store/queries/application';
import { useGetAllSkillsForCoursesQuery } from '@/store/queries/skill';
import { useGetPersonsQuery } from '@/store/queries/person';
import { Box } from '@mui/material';
import PreviousCourse from '@/components/molecules/previous-course';
import { Membership } from '@/types/api/learning';
import useGetProgramsByIdHook from '@/store/hooks/useGetProgramsByIdHook';
import ErrorScenario from './scenarios/Error';
import LoadingScenario from './scenarios/Loading';
import ReenrollIScenario from './scenarios/ReenrollI';
import ReenrollScenario from './scenarios/Reenroll';
import PrepareForClassNewStudentScenario from './scenarios/PrepareForClassNewStudent';
import PrepareForClassReenrollScenario from './scenarios/PrepareForClassReenroll';
import AlumniReenrollScenario from './scenarios/AlumniReenroll';
import DroppedScenario from './scenarios/Dropped';
import CurrentCourseScenario from './scenarios/CurrentCourse';
import WithdrawnWithUpcomingCoursesScenario from './scenarios/WithdrawnWithUpcomingCourses';
import WithdrawnScenario from './scenarios/Withdrawn';
import AlumniSingleCourseScenario from './scenarios/AlumniSingleCourse';
import AlumniScenario from './scenarios/Alumni';
import LeaveOfAbsenceScenario from './scenarios/LeaveOfAbsence';
import UpcomingCourseScenario from './scenarios/UpcomingCourse';
import GraduatedScenario from './scenarios/Graduated';
import NearGraduatedScenario from './scenarios/NearGraduated';
import RescheduleScenario from './scenarios/Reschedule';
import WelcomeScenario from './scenarios/Welcome';
import UpcomingSmallScenario from './scenarios/UpcomingSmall';

// Define the scenarios in priority order
const scenarios: { name: string, instance: CourseInfoScenarioInterface }[] = [
  { name: 'ErrorScenario', instance: ErrorScenario() },
  { name: 'LoadingScenario', instance: LoadingScenario() },
  { name: 'ReenrollIScenario', instance: ReenrollIScenario() },
  { name: 'ReenrollScenario', instance: ReenrollScenario() },
  { name: 'PrepareForClassNewStudentScenario', instance: PrepareForClassNewStudentScenario() },
  { name: 'PrepareForClassReenrollScenario', instance: PrepareForClassReenrollScenario() },
  { name: 'AlumniReenrollScenario', instance: AlumniReenrollScenario() },
  { name: 'UpcomingSmallScenario', instance: UpcomingSmallScenario() },
  { name: 'DroppedScenario', instance: DroppedScenario() },
  { name: 'CurrentCourseScenario', instance: CurrentCourseScenario() },
  { name: 'WithdrawnWithUpcomingCoursesScenario', instance: WithdrawnWithUpcomingCoursesScenario() },
  { name: 'AlumniSingleCourseScenario', instance: AlumniSingleCourseScenario() },
  { name: 'WithdrawnScenario', instance: WithdrawnScenario() },
  { name: 'LeaveOfAbsenceScenario', instance: LeaveOfAbsenceScenario() },
  { name: 'UpcomingCourseScenario', instance: UpcomingCourseScenario() },
  { name: 'AlumniScenario', instance: AlumniScenario() },
  { name: 'GraduatedScenario', instance: GraduatedScenario() },
  { name: 'NearGraduatedScenario', instance: NearGraduatedScenario() },
  { name: 'RescheduleScenario', instance: RescheduleScenario() },
  { name: 'WelcomeScenario', instance: WelcomeScenario() },
];

function CourseInfo() {
  const [renderedScenarioName, setRenderedScenarioName] = useState<string>(null);
  if (!renderedScenarioName) {
    addPerformanceMark(COURSE_INFO_START_MARK_NAME);
  }

  useEffect(() => {
    if (renderedScenarioName) {
      addPerformanceMark(COURSE_INFO_RENDERED_MARK_NAME, renderedScenarioName);
    }
  }, [renderedScenarioName]);

  // Get all the data and flags
  const personId = getPersonIdFromSessionStorage();

  const {
    isError: primaryProgramError,
    isLoading: primaryProgramLoading,
    data: { primaryProgram, programsList = [] } = {},
  } = useGetProgramsQuery({ personId });

  // using this until we switch to v2 RTK
  const {
    data: { selectedProgramId },
  } = useGetProgramsByIdHook(personId);

  const { data: loa = null, isLoading: loaLoading } = useGetStudentLoaQuery(
    { personId },
  );

  const {
    data: { academicCounselor, enrollmentRep } = {},
    isLoading: universityContactsLoading,
  } = useGetUniversityContactsQuery({ personId });

  const {
    data: { applicationType, applicationStartDate, application, applicationId } = {},
    isLoading: applicationLoading,
  } = useGetLatestWorkflowQuery({ personId });

  const {
    isLoading: programProgressLoading,
    data: {
      isDegreeAudit,
      programProgress: primaryProgramProgress,
      programs,
    } = {},
  } = useGetProgramProgressDataByProgramIdQuery(
    { programId: primaryProgram?.id },
    { skip: !primaryProgram?.id },
  );

  const {
    data: { enrollmentTasks, completedTasks } = {},
    isLoading: taskLoading,
  } = useGetTasksQuery(
    { personId, applicationId },
    { skip: !applicationId },
  );
  const hasCompletedEnrollmentTasks = completedTasks?.filter(({ type }) => type === 'ENROLLMENT').length > 1;

  const {
    data: {
      memberships = [],
      courses = [],
    } = {},
    isError: courseError,
    isLoading: courseLoading,
  } = useGetCoursesByMembershipsQuery(
    { personId },
    { skip: !personId },
  ) as {
    data: {
      courses: Course[],
      memberships: Membership[]
    },
    isLoading: boolean,
    isError: boolean
  };

  const sourceIds = memberships?.map((membership: { sourceId: string }) => membership?.sourceId);

  const currentCourses = getCurrentCourses(courses);
  const futureCoursesInNext30Days = getFutureCourses(courses, 30);
  const futureCoursesInNext7Days = getFutureCourses(courses, 7);
  const futureCoursesInNext180Days = getFutureCourses(courses, 180);
  const pastCourses = getPastCourses(courses);
  const previousCourses = getPreviousCourses(courses);

  const enrolledCurrentCourses = getEnrolledCourses(currentCourses);

  const droppedCurrentCourses = getDroppedCourses(currentCourses);

  const selectedProgram = programsList.length > 1 ? programsList.find(
    (program) => program?.id === selectedProgramId,
  ) : primaryProgram;

  const enrolledAndDroppedCourses = useMemo(() => (
    droppedCurrentCourses?.length
      ? [...enrolledCurrentCourses, ...droppedCurrentCourses]
      : enrolledCurrentCourses
  ), [enrolledCurrentCourses, droppedCurrentCourses]);

  const { data: skillsForCourses, isLoading: courseSkillsLoading } = useGetAllSkillsForCoursesQuery(
    { courses: enrolledAndDroppedCourses },
    { skip: !enrolledAndDroppedCourses?.length },
  );

  const courseSkills = useMemo(
    () => aggregateCourseSkills(skillsForCourses),
    [skillsForCourses],
  );

  const {
    data: primaryProgramCourseOutcomes = null,
    isLoading: courseOutcomesLoading,
  } = useGetCourseOutcomesQuery({
    programId: primaryProgram?.id,
  }, { skip: !primaryProgram?.id });

  const { data: skillsForProgram, isLoading: programSkillsLoading } = useGetSkillsByProgramIdQuery({
    programId: primaryProgram?.code,
    version: primaryProgram?.version,
  }, { skip: !primaryProgram?.code });

  const canUserViewSkills = useMemo(() => {
    if (primaryProgram && skillsForProgram) {
      const skillLaunchDate = skillsForProgram?.custom_metadata?.find(
        (metadata: { title: string }) => metadata?.title === 'Skills Launch Date',
      );
      if (!skillLaunchDate?.metadata_value) return false;
      const skillStartDate = toDateTime(skillLaunchDate?.metadata_value);
      const programStartDate = toDateTime(primaryProgram?.startDate);
      if (!skillStartDate.isValid || !programStartDate.isValid) return false;
      return programStartDate >= skillStartDate;
    }
    return false;
  }, [skillsForProgram, primaryProgram]);

  const {
    data: instructors = [],
    isLoading: instructorsLoading,
  } = useGetInstructorsBySourceIdsQuery({ sourceIds });

  const instructorsForMemberships = useMemo(() => {
    if (!instructors?.length) return {};
    return instructors.reduce((acc: { [key: string]: Instructor }, curr: Instructor) => ({
      ...acc,
      [curr.membershipId]: curr,
    }), {});
  }, [instructors]);

  const {
    data: { userDefined = [] } = {},
    isLoading: personLoading,
  } = useGetPersonsQuery({ personId }, { skip: !personId });

  const {
    data: { programOfferingId } = {},
    isLoading: applicationInfoLoading,
  } = useGetApplicationInfoByIdQuery(
    { applicationId },
    { skip: !applicationId },
  );

  const {
    data: programOffering,
    isLoading: programOfferingLoading,
  } = useGetProgramInfoByProgramOfferingIdQuery(
    { programOfferingId },
    { skip: !programOfferingId },
  );

  const courseInfoData: CourseInfoData = {
    parentComponentName: 'CourseInfo',
    loading: {
      courses: courseLoading,
      primaryProgram: primaryProgramLoading,
      programProgress: programProgressLoading,
      loa: loaLoading,
      tasks: taskLoading,
      enrollmentApp: applicationLoading,
      programOffering: programOfferingLoading,
      universityContacts: universityContactsLoading,
      courseSkills: courseSkillsLoading,
      courseOutcomes: courseOutcomesLoading,
      programSkills: programSkillsLoading,
      instructors: instructorsLoading,
      person: personLoading,
      applicationInfo: applicationInfoLoading,
    },
    error: {
      courses: courseError,
      primaryProgram: primaryProgramError,
    },
    primaryProgram: {
      name: primaryProgram?.description,
      isCBEDA: primaryProgram?.cbeDaProgram === 'TRUE',
      isDegreeAudit,
      type: primaryProgram?.type,
      qualificationLevel: primaryProgram?.qualificationLevel,
    },
    academicCounselor: {
      firstName: academicCounselor?.[0]?.firstName,
      lastName: academicCounselor?.[0]?.lastName,
      phoneNumber: academicCounselor?.[0]?.phoneNumber || '(800) 366-9699',
      email: academicCounselor?.[0]?.email,
    },
    enrollmentRep: {
      firstName: enrollmentRep?.[0]?.firstName,
      lastName: enrollmentRep?.[0]?.lastName,
      phoneNumber: enrollmentRep?.[0]?.phoneNumber || '800-858-2523',
      email: enrollmentRep?.[0]?.email,
    },
    enrollmentApp: {
      type: applicationType,
      startTime: applicationStartDate,
      submittedTime: application?.submittedTime,
      reviewStatus: application?.reviewStatus,
      version: application?.version,
      ID: applicationId,
      submissionStatus: application?.submissionStatus,
      program: programOffering || {},
    },
    hasCompletedEnrollmentTasks,
    hasIncompleteEnrollmentTasks: enrollmentTasks?.length > 1,
    courseMemberships: sourceIds,
    currentCourses,
    futureCoursesInNext30Days,
    futureCoursesInNext7Days,
    futureCoursesInNext180Days,
    pastCourses,
    courseSkills,
    canUserViewSkills,
    instructorsForMemberships,
    leaveOfAbsenceDate: loa?.endDate,
    primaryProgramCourseOutcomes,
    primaryProgramProgress,
    selectedProgram,
    skillsBasedProgram: '',
    programs,
    userDefinedAttributes: userDefined,
    enrollmentStatus: primaryProgram?.statuses?.find((status: { statusType: string }) => (status.statusType === 'EnrollmentStatus')) || null,
    admissionStatus: primaryProgram?.statuses?.find((status: { statusType: string }) => (status.statusType === 'AdmissionStatus')) || null,
  };

  const getPreviousCourseScenario = () => {
    const previousCourseList = previousCourses.map((course) => (
      {
        membershipId: course.membershipId,
        courseCode: course.templateCode,
        courseTitle: course.title,
        linkURL: getClassroomClassUrl(
          getClassroomKey({ isCBEDA: primaryProgram?.cbeDaProgram === 'TRUE' }),
          { sectionId: course?.id },
        ),
        grade: course?.grade,
      }));

    if (previousCourses.length) {
      return (
        <PreviousCourse
          courses={previousCourseList}
          courseOutcomes={primaryProgramCourseOutcomes}
        />
      );
    }
    return null;
  };

  // Loop through the scenarios and return the first that should show
  const matchedScenario = scenarios.find(
    (scenario) => scenario.instance.shouldShow(courseInfoData),
  );

  if (!matchedScenario) return null;

  if (matchedScenario.name !== renderedScenarioName) {
    setRenderedScenarioName(matchedScenario.name);
  }
  const { Component } = matchedScenario.instance;

  return (
    <Box data-testid="course-info" width="100%">
      { Component && <Component courseInfoData={courseInfoData} /> }
      <Box width="100%" my={5}>
        {getPreviousCourseScenario()}
      </Box>
    </Box>
  );
}

export default CourseInfo;
