import LeaveOfAbsenceTile from '@/components/molecules/leave-of-absence-tile';
import { CourseInfoData } from '@/types/courseInfoData';
import { CourseInfoScenarioInterface } from '@/types/courseInfoScenarios';

const isLOA = (courseInfoData: CourseInfoData) => courseInfoData?.enrollmentStatus?.status === 'A';

const getContactInfo = (courseInfoData: CourseInfoData) => {
  const { academicCounselor, enrollmentRep, primaryProgram } = courseInfoData;
  const isPrimaryProgramTypeProgram = primaryProgram?.type === 'Program';
  const defaultPhone = isPrimaryProgramTypeProgram ? '(800) 858-2523' : '(800) 366-9699';
  const contact = isPrimaryProgramTypeProgram ? enrollmentRep : academicCounselor;
  const { firstName, lastName, phoneNumber } = contact;
  return {
    contactName: firstName && lastName ? `${firstName} ${lastName}` : '',
    contactPhone: phoneNumber || defaultPhone,
  };
};

function LeaveOfAbsenceScenario(): CourseInfoScenarioInterface {
  return {
    shouldShow: (courseInfoData: CourseInfoData) => isLOA(courseInfoData),
    Component: ({ courseInfoData }: { courseInfoData: CourseInfoData }) => {
      const {
        futureCoursesInNext180Days,
        leaveOfAbsenceDate,
        parentComponentName,
      } = courseInfoData;
      const { contactName, contactPhone } = getContactInfo(courseInfoData);
      return (
        <LeaveOfAbsenceTile
          futureCourses={futureCoursesInNext180Days}
          phone={contactPhone}
          loaReturnDateString={leaveOfAbsenceDate}
          parentComponentName={parentComponentName}
          contactName={contactName}
        />
      );
    },
  };
}

export default LeaveOfAbsenceScenario;
