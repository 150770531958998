import { useState, useEffect } from 'react';
import { Grid,
  CircularProgress,
  Fade,
  Skeleton,
  Typography,
  useMediaQuery,
  useTheme,
  Box } from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import IconItem from '@/components/molecules/icon-item';
import Icon from '@/components/atoms/icon';
import AcademicStatus from '@/components/molecules/academic-status';
import ErrorMessage from '@/components/atoms/error-message';
import SelectionDropdown from '@/components/molecules/selection-dropdown';
import { getPersonIdFromSessionStorage } from '@/helpers/auth';
import { useGetUniversityContactsQuery } from '@/store/queries/contacts';
import Tooltip from '@/components/atoms/tooltip';
import { StyledCampus,
  ContactEmail,
  ContactMessage,
  ContactName,
  Details,
  DropdownContainer,
  EnrollmentRep,
  EnrollmentRepName,
  NoContactMessage,
  StyledAccordion,
  Summary,
  SummaryHeader,
  SummaryLabel,
  UserIcon,
  UserIconContainer } from './ProgramContacts.styles';

type ProgramContactsProps = {
  selectionCallback?: Function,
  initialProgramContactIndex?: number,
  isReEnroll?: boolean,
  isAlumni?: boolean,
  isEngagementAP?: boolean,
};

function ProgramContacts({
  selectionCallback,
  initialProgramContactIndex = 0,
  isReEnroll,
  isAlumni,
  isEngagementAP,
}: ProgramContactsProps) {
  const { data: { enrollmentRep, academicCounselor } =
  {}, isLoading, isError } = useGetUniversityContactsQuery(
    { personId: getPersonIdFromSessionStorage() },
  );

  const [currentSelectedCounselor, setCurrentSelectedCounselor] = useState(
    academicCounselor?.[initialProgramContactIndex]
    || academicCounselor?.[0] || [],
  );

  const theme = useTheme();
  const firstPanel = isEngagementAP ? 'panel0' : 'panel1';
  const defaultExpanded = isReEnroll ? '' : firstPanel;
  const [expanded, setExpanded] = useState(defaultExpanded);
  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down('sm'));

  const handleChange = (panel: string) => (event: any, isExpanded: boolean) => {
    if (panel === 'panel0' && isExpanded === true) {
      setExpanded('panel0');
    } else if (panel === 'panel0' && isExpanded === false) {
      setExpanded('panel1');
    } else if (panel === 'panel1' && isExpanded === true) {
      setExpanded('panel1');
    } else if (panel === 'panel1' && isExpanded === false) {
      setExpanded('panel2');
    } else if (panel === 'panel2' && isExpanded === true) {
      setExpanded('panel2');
    } else if (panel === 'panel2' && isExpanded === false) {
      setExpanded(firstPanel);
    }
  };

  useEffect(() => {
    setCurrentSelectedCounselor(
      academicCounselor?.[initialProgramContactIndex]
      || academicCounselor?.[0],
    );
  }, [academicCounselor, initialProgramContactIndex, setCurrentSelectedCounselor]);

  const screenReaderText = (
    representative: {
      phoneNumber?: string; firstName?: string; lastName?: string; email?: string; },
    idSuffix: string,
  ) => (
    <Box sx={visuallyHidden}>
      {representative.phoneNumber && <span id={`sr_rep_phone_${idSuffix}`}>{`Call ${representative.firstName} ${representative.lastName}, ${representative.phoneNumber}`}</span>}
      {representative && <span id={`sr_rep_text_${idSuffix}`}>Text 89099</span>}
      {representative.email && <span id={`sr_rep_email_${idSuffix}`}>{`Email ${representative.email}`}</span>}
    </Box>
  );

  return (
    <div>
      {isEngagementAP && (
        <StyledAccordion
          id="accordion0"
          square
          elevation={0}
          expanded={expanded === 'panel0'}
          onChange={handleChange('panel0')}
        >
          <Summary
            aria-controls="panel0bh-content"
            id="panel0bh-header"
          >
            <SummaryHeader variant="body5">
              <Icon
                icon={expanded === 'panel0' ? 'icon-toggle-close' : 'icon-toggle-open'}
                aria-hidden="true"
                color={theme.palette.primary.main}
                id={expanded === 'panel0' ? '-close' : '-open'}
              />
              {'    '}
              <SummaryLabel>
                ENGAGEMENT
                {'  '}
              </SummaryLabel>
              <Tooltip
                tooltipMessage="Continue posting in at least one of your classes every 14 days to avoid being dropped from all your courses."
                tooltipAriaLabel="tooltip engagment"
                placement="top"
                iconHeight={2}
                iconWidth={2}
              />
            </SummaryHeader>
          </Summary>
          <Details>
            <Typography variant="body4" data-programcontacts-id="engagement" component="div">
              Engagement AP
            </Typography>
          </Details>
        </StyledAccordion>
      )}

      <StyledAccordion
        id="accordion1"
        square
        elevation={0}
        expanded={expanded === 'panel1'}
        onChange={handleChange('panel1')}
      >
        <Summary
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <SummaryHeader variant="body5">
            <Icon
              color={theme.palette.primary.main}
              icon={expanded === 'panel1' ? 'icon-toggle-close' : 'icon-toggle-open'}
              aria-hidden="true"
              id={expanded === 'panel1' ? '-close' : '-open'}
            />
            {'    '}
            <SummaryLabel>
              {isLoading ? (
                <Skeleton width={100} />
              ) : (
                <>
                  {isReEnroll && 'QUESTIONS?'}
                  {!isReEnroll && academicCounselor?.length === 1 && 'ACADEMIC COUNSELOR'}
                  {!isReEnroll && academicCounselor?.length === 0 && 'NEED HELP?'}
                  {!isReEnroll && academicCounselor?.length > 1 && (
                  <>
                    ACADEMIC COUNSELOR
                    {' '}
                    {' '}
                    {academicCounselor.indexOf(currentSelectedCounselor) + 1}
                    {' '}
                    OF
                    {' '}
                    {' '}
                    {academicCounselor?.length}
                  </>
                  )}
                </>
              )}
            </SummaryLabel>
          </SummaryHeader>
        </Summary>

        <Details>
          <Typography variant="body5" data-programcontacts-id="counselors">
            {!isError ? (
              <div>
                {!isLoading ? (
                  <>
                    {!isReEnroll && academicCounselor?.length === 0 && (
                    <>
                      <NoContactMessage>
                        Talk to an Academic Counselor
                      </NoContactMessage>
                      <UserIconContainer>
                        <UserIcon>
                          <IconItem
                            text="89099"
                            icon="icon-text-sms-mc"
                            url="sms:89099"
                            id=""
                            type="link"
                          />
                        </UserIcon>
                        <UserIcon>
                          <IconItem
                            text="(800) 366-9699"
                            icon="icon-support-mc"
                            url={`tel:${'(800) 366-9699'}`}
                            id=""
                            type="link"
                          />
                        </UserIcon>
                      </UserIconContainer>
                    </>
                    )}

                    {isReEnroll && Object.keys(enrollmentRep || {}).length === 0 && (
                    <>
                      <NoContactMessage>
                        Talk to an Enrollment Representative
                      </NoContactMessage>
                      <UserIconContainer>
                        <UserIcon>
                          <IconItem
                            text={isAlumni ? '(800) 858-2523' : '(866) 992-8146'}
                            icon="icon-support-mc"
                            url={isAlumni ? `tel:${'(800) 858-2523'}` : `tel:${'(866) 992-8146'}`}
                            id=""
                            type="link"
                          />
                        </UserIcon>
                      </UserIconContainer>
                    </>
                    )}

                    {isReEnroll && Object.keys(enrollmentRep || {}).length === 1 && (
                    <>
                      <ContactMessage>
                        Talk to your Enrollment Representative
                      </ContactMessage>
                      {screenReaderText(enrollmentRep, `ac_${enrollmentRep.firstName}_${enrollmentRep.lastName}`)}
                      <EnrollmentRepName>
                        {`${enrollmentRep[0].firstName} ${enrollmentRep[0].lastName}`}
                      </EnrollmentRepName>

                      <Grid
                        container
                        direction={isMobile ? 'column' : 'row'}
                        justifyContent="start"
                        alignItems={isMobile ? 'flex-start' : 'center'}
                      >
                        <EnrollmentRep>
                          <IconItem
                            icon="icon-email-mc"
                            text={`Email ${enrollmentRep[0].firstName}`}
                            url={`mailto:${enrollmentRep[0].email}`}
                            id="rep_email_ac"
                            type="link"
                          />
                        </EnrollmentRep>
                        <EnrollmentRep>
                          <IconItem
                            text={enrollmentRep[0].phoneNumber}
                            icon="icon-support-mc"
                            url={`tel:${enrollmentRep[0].phoneNumber}`}
                            id="rep_phone_ac"
                            type="link"
                          />
                        </EnrollmentRep>
                      </Grid>
                    </>
                    )}

                    {!isReEnroll && academicCounselor?.length === 1 && (
                    <>
                      <ContactName>
                        {`${academicCounselor[0].firstName} ${academicCounselor[0].lastName}`}
                      </ContactName>
                      <ContactEmail>
                        <IconItem
                          text={`Email ${academicCounselor[0].firstName}`}
                          url={`mailto:${academicCounselor[0].email}`}
                          id="rep_email_ac"
                          type="link"
                          icon="empty"
                        />
                      </ContactEmail>

                      <UserIconContainer>
                        <UserIcon>
                          <IconItem
                            text="89099"
                            icon="icon-text-sms-mc"
                            url="sms:89099"
                            id="rep_text_ac"
                            type="link"
                          />
                        </UserIcon>
                        <UserIcon>
                          <IconItem
                            text={academicCounselor[0].phoneNumber}
                            icon="icon-support-mc"
                            url={`tel:${academicCounselor[0].phoneNumber}`}
                            id="rep_phone_ac"
                            type="link"
                          />
                        </UserIcon>
                      </UserIconContainer>
                    </>
                    )}

                    {!isReEnroll && academicCounselor?.length > 1 && currentSelectedCounselor && (
                    <>
                      <DropdownContainer>
                        <SelectionDropdown
                          optionsList={academicCounselor.map(
                            (counselor: { firstName: any; lastName: any; }, index: any) => ({
                              value: `counselor-${index}`,
                              title: `${counselor.firstName} ${counselor.lastName}`,
                              ariaLabel: `${counselor.firstName} ${counselor.lastName}`,
                            }),
                          )}
                          selectedIndex={initialProgramContactIndex}
                          selectedCallback={(value) => {
                            const selectedCounselorIndex = academicCounselor.map((counselor: any, index: any) => `counselor-${index}`).indexOf(value);
                            const selectedCounselor = academicCounselor[selectedCounselorIndex];
                            if (selectionCallback) {
                              selectionCallback(selectedCounselorIndex);
                            }
                            setCurrentSelectedCounselor(selectedCounselor);
                          }}
                        />
                      </DropdownContainer>
                      {screenReaderText(currentSelectedCounselor, `er_${academicCounselor.indexOf(currentSelectedCounselor)}`)}
                      <ContactEmail>
                        <IconItem
                          text={`Email ${currentSelectedCounselor.firstName}`}
                          url={`mailto:${currentSelectedCounselor.email}`}
                          id="rep_email_er"
                          type="link"
                          icon="empty"
                        />

                        <UserIconContainer>
                          <UserIcon>
                            <IconItem
                              text="89099"
                              icon="icon-text-sms-mc"
                              url="sms:89099"
                              id="rep_text_er"
                              type="link"
                            />
                          </UserIcon>
                          <UserIcon>
                            <IconItem
                              text={currentSelectedCounselor.phoneNumber}
                              icon="icon-support-mc"
                              url={`tel:${currentSelectedCounselor.phoneNumber}`}
                              id="rep_phone_er"
                              type="link"
                            />
                          </UserIcon>
                          <StyledCampus>
                            <Typography variant="body4">
                              {' '}
                              Campus:
                              {' '}
                              {' '}
                              {currentSelectedCounselor.location}
                            </Typography>
                          </StyledCampus>
                        </UserIconContainer>
                      </ContactEmail>
                    </>
                    )}
                  </>
                ) : (
                  <Fade data-programcontacts-id="loading" in timeout={500} style={{ transitionDelay: '500ms' }}>
                    <CircularProgress />
                  </Fade>
                )}
              </div>
            ) : (
              <ErrorMessage errorComponent="ProgramContacts" />
            )}
          </Typography>
        </Details>
      </StyledAccordion>

      {!isReEnroll && (
        <StyledAccordion
          id="accordion2"
          square
          elevation={0}
          expanded={expanded === 'panel2'}
          onChange={handleChange('panel2')}
        >
          <Summary
            aria-controls="panel2bh-content"
            id="panel2bh-header"
          >
            <SummaryHeader variant="body5">
              <Icon
                icon={expanded === 'panel2' ? 'icon-toggle-close' : 'icon-toggle-open'}
                aria-hidden="true"
                color={theme.palette.primary.main}
                id={expanded === 'panel2' ? '-close' : '-open'}
              />
              {'    '}
              <SummaryLabel>
                STATUS
                {'  '}
              </SummaryLabel>
            </SummaryHeader>
          </Summary>
          <Details>
            <Typography variant="body4" data-programcontacts-id="status" component="div">
              <AcademicStatus />
            </Typography>
          </Details>
        </StyledAccordion>
      )}
    </div>
  );
}

export default ProgramContacts;
