import { CourseInfoData } from '@/types/courseInfoData';
import { CourseInfoScenarioInterface } from '@/types/courseInfoScenarios';
import PrepareForClassTile from '@/components/molecules/prepare-for-class-tile';
import { newStudent, getEditApplicationLink } from '@/myphoenix/utils/enrollment-functions';
import { toDateTimeFromMs } from '@/myphoenix/utils/date-time-functions';

function PrepareForClassNewStudentScenario(): CourseInfoScenarioInterface {
  return {
    shouldShow: (courseInfoData: CourseInfoData) => !courseInfoData?.currentCourses?.length
      && !courseInfoData?.futureCoursesInNext7Days?.length
      && courseInfoData?.enrollmentApp?.ID
      && newStudent(courseInfoData?.enrollmentStatus),
    Component: ({ courseInfoData }: { courseInfoData: CourseInfoData }) => {
      const {
        enrollmentApp: {
          program: { description },
          reviewStatus, submissionStatus, submittedTime, version, ID, type,
        },
        enrollmentRep: { firstName, lastName, phoneNumber, email },
        hasCompletedEnrollmentTasks,
        hasIncompleteEnrollmentTasks,
        primaryProgram: { isCBEDA },
      } = courseInfoData;
      return (
        <PrepareForClassTile
          id="NewStudent"
          programTitle={description}
          contactFirstName={firstName}
          contactLastName={lastName}
          contactPhoneNumber={phoneNumber}
          contactEmail={email}
          applicationReviewStatus={reviewStatus}
          applicationSubmissionStatus={submissionStatus}
          applicationSubmittedAt={
            Number.isInteger(submittedTime)
              ? toDateTimeFromMs(submittedTime).toISO()
              : null
          }
          hasCompletedEnrollmentTasks={hasCompletedEnrollmentTasks}
          hasIncompleteEnrollmentTasks={Number(version) >= 4 ? hasIncompleteEnrollmentTasks : true}
          isCBEDA={isCBEDA}
          editApplicationLink={getEditApplicationLink(Number(version), reviewStatus)}
          applicationId={ID}
          applicationType={type}
        />
      );
    },
  };
}

export default PrepareForClassNewStudentScenario;
