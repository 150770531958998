import SingleTile from '@/components/molecules/single-tile';
import { CourseInfoData } from '@/types/courseInfoData';
import { CourseInfoScenarioInterface } from '@/types/courseInfoScenarios';

function LoadingScenario(): CourseInfoScenarioInterface {
  return {
    shouldShow: (courseInfoData: CourseInfoData) => Object.keys(courseInfoData.loading).some(
      (key) => courseInfoData.loading[key as keyof CourseInfoData],
    ),
    Component: (
      { courseInfoData: { parentComponentName } }
      : { courseInfoData: CourseInfoData },
    ) => (
      <SingleTile parentComponentName={parentComponentName} loading icon="" title="" />
    ),
  };
}

export default LoadingScenario;
