import { useMemo } from 'react';
import { useGetAttendancesByMembershipIdsQuery, useGetActivityGroupsBySectionIdsQuery } from '@/store/queries/v2/attendances';
import { CourseActivityGroups } from '@/types/api/learning';
import { Course } from '@/types/courseInfoData';
import { ByIdObject } from '@/helpers/object';

export type Attendance = {
  id?: string,
  membershipId?: string,
  attendance?: string,
};

const convertToByMembershipIdObject = (
  courses: Course[],
  sourceIds: string[],
  attendances: Attendance[],
  activityGroups: CourseActivityGroups[],
) => {
  let byMembershipId: ByIdObject = {};
  if (courses && Array.isArray(courses) && attendances && Array.isArray(attendances)
  && activityGroups && Array.isArray(activityGroups)) {
    courses.forEach((course: Course) => {
      const { id, membershipId } = course;
      const filteredAttendances = attendances?.filter(
        (attendance) => attendance?.membershipId === membershipId,
      );
      const sourceIndex: number = sourceIds.findIndex((sourceId) => id === sourceId);
      if (membershipId && sourceIndex > -1) {
        byMembershipId = {
          ...byMembershipId,
          [membershipId]: {
            attendances: filteredAttendances,
            activityGroups: activityGroups[sourceIndex].activitygroups,
            allowedAbsences: activityGroups[sourceIndex].allowedAbsences,
          },
        };
      }
    });
  }
  return { byMembershipId };
};

export const useGetAttendancesHook = (courses: Course[]) => {
  const membershipIds: string[] = courses.map(
    (course: Course) => course.membershipId,
  );

  const sourceIds: string[] = courses.map(
    (course: Course) => course.id,
  );

  const {
    data: attendanceData = [],
    isFetching: isGetAttendancesByMembershipIdsFetching,
    isError: isGetAttendancesByMembershipIdsError,
  } = useGetAttendancesByMembershipIdsQuery(
    { membershipIds },
    { skip: !membershipIds || membershipIds?.length === 0 },
  );

  const {
    data: activityGroupData = [],
    isFetching: isGetActivityGroupsBySectionIdsFetching,
    isError: isGetActivityGroupsBySectionIdsError,
  } = useGetActivityGroupsBySectionIdsQuery(
    { sourceIds },
    { skip: !sourceIds || sourceIds?.length === 0 },
  );

  const data = useMemo(() => {
    if (
      isGetAttendancesByMembershipIdsFetching
      || isGetAttendancesByMembershipIdsError
      || isGetActivityGroupsBySectionIdsFetching
      || isGetActivityGroupsBySectionIdsError
    ) {
      return {};
    }

    const allAttendance: Attendance[] = [];
    attendanceData?.forEach((allAttendances) => (
      allAttendances.attendances.forEach(
        (attendance: { id: string, membershipId: string, attendance: string }) => (
          allAttendance.push(attendance)
        ),
      )));

    const attendances = convertToByMembershipIdObject(
      courses,
      sourceIds,
      allAttendance,
      activityGroupData,
    );
    return {
      attendances,
    };
  }, [
    courses,
    sourceIds,
    isGetAttendancesByMembershipIdsFetching,
    isGetAttendancesByMembershipIdsError,
    isGetActivityGroupsBySectionIdsFetching,
    isGetActivityGroupsBySectionIdsError,
    attendanceData,
    activityGroupData]);
  return {
    data,
    isFetching: isGetAttendancesByMembershipIdsFetching || isGetActivityGroupsBySectionIdsFetching,
    isError: isGetAttendancesByMembershipIdsError || isGetActivityGroupsBySectionIdsError,
  };
};
