import ConfettiComponent from '@/components/atoms/confetti';
import LinkButton from '@/components/atoms/link-button';
import { getPersonIdFromSessionStorage } from '@/helpers/auth';
import { AchievementTypes } from '@/helpers/enums/notifications';
import { getLatestAchievement } from '@/store/helpers/notifications';
import { useGetNotificationsQuery, useUpdateNotificationAsReadOrUnreadMutation } from '@/store/queries/notifications';
import { useGetOfficialNameQuery } from '@/store/queries/person';
import { Backdrop, BackdropProps, Box, Dialog, Theme, useMediaQuery } from '@mui/material';
import { DateTime } from 'luxon';
import Image from 'next/image';
import { useEffect, useMemo, useState } from 'react';
import { useClickEventTracking } from '@/hooks/useClickEventTracking';
import { componentNameToMyPhxKebabFormat } from '@/helpers/string-utils';
import { navigate } from '@/helpers/window-functions';
import { GetTemplate } from './AchievementsModal.constants';
import { BodyTypography, CtaButton, DialogPaper, TitleTypography } from './AchievementsModal.styles';

type AchievementsModalProps = {
  showAchievementsModal?: boolean;
  ctaText?: string;
  ctaLink?: string;
};

type AwardPeriod = {
  year: string;
  startMonth: string;
  endMonth: string;
};

function getAwardPeriod(expiryDate: DateTime): AwardPeriod {
  const end = expiryDate.toUTC().set({ day: 1 }).minus({ months: 6 });
  const start = end.toUTC().minus({ months: 5 });
  return {
    year: start.toFormat('yyyy'),
    startMonth: start.toFormat('MMMM'),
    endMonth: end.toFormat('MMMM'),
  };
}

// Component to use as backdrop in a modal to show confetti
// between the dimmed background and the modal itself.
function ConfettiBackdrop(props: BackdropProps) {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  return (
    <Backdrop {...props}>
      {isMobile ? null : <ConfettiComponent />}
    </Backdrop>
  );
}

function AchievementsModal({
  showAchievementsModal = false,
  ctaText = 'View in Academic Plan',
  ctaLink = '/academic-plan.html#achievements',
}: AchievementsModalProps) {
  const trackClick = useClickEventTracking();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const componentName = 'AchievementsModal';

  const personId = getPersonIdFromSessionStorage();
  const { data: notifications } = useGetNotificationsQuery({ personId });
  const { data: { firstName } = {} } = useGetOfficialNameQuery({
    personId,
  }, { skip: !personId });
  const [updateNotification] = useUpdateNotificationAsReadOrUnreadMutation();

  const latestAchievement = useMemo(
    () => (getLatestAchievement(notifications, true, AchievementTypes.PresidentsList)),
    [notifications],
  );
  const template = useMemo(() => GetTemplate({
    achievementType: latestAchievement?.type,
    firstName: String(firstName),
    ctaText,
    resources: latestAchievement?.resources,
  }), [latestAchievement, firstName, ctaText]);
  const period = useMemo(
    () => (
      latestAchievement?.expiryDate
        ? getAwardPeriod(latestAchievement?.expiryDate as DateTime)
        : null
    ),
    [latestAchievement],
  );

  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (latestAchievement) {
      setIsModalOpen(true);
    }
  }, [latestAchievement]);

  const dismissAndRedirect = (link: string) => {
    trackClick(
      componentName,
      [{ text: 'link' }, { type: latestAchievement?.type }],
    );
    setIsModalOpen(false);
    updateNotification({ personId, notificationId: latestAchievement?.id, read: true })
      .finally(() => {
        if (link) {
          navigate(link);
        }
      });
  };

  const dismissAndMarkAsRead = (action: string) => {
    trackClick(
      componentName,
      [{ text: action }, { type: latestAchievement?.type }],
    );
    setIsModalOpen(false);
    updateNotification({ personId, notificationId: latestAchievement?.id, read: true });
  };

  if (!showAchievementsModal || !latestAchievement) return null;

  return (
    <div>
      <Dialog
        data-component={componentNameToMyPhxKebabFormat(componentName)}
        open={isModalOpen}
        onClose={() => dismissAndMarkAsRead('blur')}
        role="dialog"
        aria-modal
        aria-labelledby="achievement-message"
        fullScreen={isMobile}
        components={{
          Backdrop: ConfettiBackdrop,
        }}
        PaperComponent={DialogPaper}
      >
        <Box display="flex" flexDirection="column">
          <LinkButton
            variant="red-hover"
            onClick={() => dismissAndMarkAsRead('close')}
            sx={{ display: 'flex', alignSelf: 'end' }}
            disableRipple
          >
            Close
          </LinkButton>
          {template && period && (
            <Box sx={{ pt: 2.7 }} display="flex" flexDirection="column" alignItems="center" textAlign="center">
              <Box sx={{ mb: 2, pt: { xs: 5, sm: 0 } }}>
                {template?.imgFile
                  ? (
                    <Image
                      src={`/images/badges/achievements/${period.year}/${template.imgFile}`}
                      alt={template.altText}
                      width={200}
                      height={200}
                    />
                  )
                  : (
                    <Image
                      src={template.imageUrl}
                      alt={`Badge for ${template.skill}`}
                      width={200}
                      height={200}
                    />
                  )}
              </Box>
              <TitleTypography component="h2" variant="body4" id="achievement-message">
                {template.header}
              </TitleTypography>

              <BodyTypography component="p" variant="body4" id="achievement-message-content">
                {template.message}
              </BodyTypography>
              {template?.ctaText && (
                <CtaButton
                  variant="contained"
                  onClick={() => dismissAndRedirect(template?.acceptBadgeUrl || ctaLink)}
                >
                  {template.ctaText}
                </CtaButton>
              )}
            </Box>
          )}
        </Box>
      </Dialog>
    </div>
  );
}

export default AchievementsModal;
