import { CourseInfoData } from '@/types/courseInfoData';
import { CourseInfoScenarioInterface } from '@/types/courseInfoScenarios';
import AlumniReEnrollTile from '@/components/molecules/alumni-reenroll-tile';
import { getEditApplicationLink } from '@/myphoenix/utils/enrollment-functions';
import { toDateTimeFromMs } from '@/myphoenix/utils/date-time-functions';

function AlumniReenrollScenario(): CourseInfoScenarioInterface {
  return {
    shouldShow: (courseInfoData: CourseInfoData) => (
      !courseInfoData.currentCourses?.length
      && !courseInfoData.futureCoursesInNext7Days?.length
      && courseInfoData.enrollmentStatus?.status === 'G'
      && courseInfoData.enrollmentApp?.startTime
      && courseInfoData.enrollmentApp?.type === 'ALUMNI'
    ),
    Component: (
      { courseInfoData: {
        primaryProgram,
        enrollmentRep,
        enrollmentApp,
        hasCompletedEnrollmentTasks,
        hasIncompleteEnrollmentTasks,
      } }
      : { courseInfoData: CourseInfoData },
    ) => (
      <AlumniReEnrollTile
        programTitle={enrollmentApp.program?.description}
        contactFirstName={enrollmentRep.firstName}
        contactLastName={enrollmentRep.lastName}
        contactPhoneNumber={enrollmentRep.phoneNumber}
        contactEmail={enrollmentRep.email}
        applicationReviewStatus={enrollmentApp.reviewStatus}
        applicationSubmissionStatus={enrollmentApp.submissionStatus}
        applicationSubmittedAt={
          Number.isInteger(enrollmentApp.submittedTime)
            ? toDateTimeFromMs(enrollmentApp.submittedTime).toISO()
            : null
        }
        hasCompletedEnrollmentTasks={hasCompletedEnrollmentTasks}
        hasIncompleteEnrollmentTasks={hasIncompleteEnrollmentTasks}
        isCBEDA={primaryProgram.isCBEDA}
        completeTasksURL="/message-center.html"
        editApplicationLink={
          getEditApplicationLink(
            Number(enrollmentApp.version),
            enrollmentApp.reviewStatus,
          )
        }
      />
    ),
  };
}

export default AlumniReenrollScenario;
