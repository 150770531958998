import ArrowLink from '@/components/atoms/arrow-link';
import Icon from '@/components/atoms/icon';
import ToggleTooltip from '@/components/atoms/tooltip';
import theme from '@/helpers/theme';
import { componentNameToMyPhxKebabFormat } from '@/helpers/string-utils';
import { useMediaQuery, TableContainer, Paper, TableRow, TableBody, Typography, TableHead, TableCell } from '@mui/material';
import {
  Root,
  StyledCourse,
  StyledCourseCode,
  StyledCourseDescription,
  CourseTitle,
  CourseLink,
  StyledTableCell,
  GradeContainer,
  DueDate,
  HiddenSpan,
  StyledTable,
  StyledGrade,
} from '@/components/molecules/previous-course/PreviousCourse.styles';
import { visuallyHidden } from '@mui/utils';
import { getCurrentDate, toDateString, toDateTime } from '@/myphoenix/utils/date-time-functions';

export type Course = {
  membershipId: string;
  courseCode: string;
  courseTitle: string;
  linkURL: string;
  grade?: string;
};

export type CourseOutcomes = {
  [membershipId: string]: {
    courseTitle: string;
    deadDate: string;
    groupId: string;
    membershipId: string;
    offeringEndDate: string;
    offeringStartDate: string;
    qualificationPoints: number;
    sectionId: string;
    templateCode: string;
  }
};

type PreviousCourseProps = {
  courses: Course[];
  courseOutcomes?: CourseOutcomes;
};

export default function PreviousCourse({
  courses,
  courseOutcomes,
}: PreviousCourseProps) {
  const componentName = 'PreviousCourse';
  const inProgress = 'Additional time to finish and submit all assignments is determined by the instructor.';
  const incomplete = 'You\'ll have additional time from the scheduled course completion date to complete and submit all coursework assignments.';
  const inProgressExt = 'The Registrar\'s Office will work with the Disability Services Counselor or the Title IX Coordinator to determine the course completion date for ADA accommodations or Title IX accommodations.';
  const isMobile = useMediaQuery(
    theme.breakpoints.only('xs'),
  );
  let gradeLabel = '';
  const displayGrade = (courseGrade: string) => {
    switch (courseGrade) {
      case 'MA':
        gradeLabel = 'Mastered';
        break;
      case 'ME':
        gradeLabel = 'Met';
        break;
      case 'NM':
        gradeLabel = 'Not Met';
        break;
      case 'I':
        gradeLabel = 'Incomplete';
        break;
      case 'IP':
        gradeLabel = 'In Progress';
        break;
      case 'IX':
        gradeLabel = 'In Progress Extension';
        break;
      case '':
      case null:
      case undefined:
        gradeLabel = 'Pending';
        break;
      default:
        gradeLabel = courseGrade;
        break;
    }
    return gradeLabel;
  };
  const getCourseOutcomeDeadDate = (membershipId: string) => (
    membershipId && courseOutcomes?.[membershipId]?.deadDate
      ? courseOutcomes[membershipId].deadDate : null);
  const hasSpecialGrades = (grade: string) => ['I', 'IP', 'IX'].includes(grade);
  const tooltipVariations = (grade: string, deadDate: string = '') => {
    const today = getCurrentDate();
    const formattedDeadDate = toDateTime(deadDate);
    if (grade === 'I') {
      return incomplete;
    } if (grade === 'IP') {
      return inProgress;
    } if (grade === 'IX') {
      if (today.hasSame(formattedDeadDate, 'day')) {
        return inProgressExt;
      } if (today.diff(formattedDeadDate, 'day').as('days') <= 7) {
        return inProgressExt;
      } if (today > formattedDeadDate) {
        return inProgressExt;
      } return inProgressExt;
    }
    return null;
  };

  return (
    <Root data-component={componentNameToMyPhxKebabFormat(componentName)}>
      <TableContainer component={Paper}>
        <StyledTable role="table">
          <caption>{courses.length > 1 ? 'PREVIOUS COURSES' : 'PREVIOUS COURSE'}</caption>
          <TableHead role="rowgroup" sx={visuallyHidden}>
            <TableRow role="row">
              <TableCell role="columnheader">Course</TableCell>
              <TableCell role="columnheader">Grade</TableCell>
            </TableRow>
          </TableHead>
          <TableBody role="rowgroup">
            {courses.map((course) => (
              <TableRow role="row" key={course.courseCode}>
                <StyledCourse role="cell">
                  <StyledCourseDescription>
                    <StyledCourseCode href={course.linkURL}>
                      {course.courseCode}
                    </StyledCourseCode>
                    <CourseTitle noWrap variant="body4" component="span">{course.courseTitle}</CourseTitle>
                  </StyledCourseDescription>
                  <CourseLink>
                    <ArrowLink
                      link={course.linkURL}
                      ariaLabel={`Go to class ${course.courseCode}`}
                      clickEvent={{
                        componentName,
                        properties: [{ courseCode: course.courseCode }],
                      }}
                    >
                      Go to class
                    </ArrowLink>
                  </CourseLink>
                </StyledCourse>
                <StyledTableCell role="cell" $hasSpecialGrades={hasSpecialGrades(course.grade)} $hasCourseGrades={!!course.grade}>
                  <GradeContainer>
                    {(course.grade) && (
                      <Icon icon="icon-star" aria-hidden="true" color={theme.palette.primary.main} id="grades" />
                    )}
                    {(!course.grade) && (
                      <Icon icon="icon-star-1" aria-hidden="true" color={theme.palette.almostBlack.l30} id="no-grades" />
                    )}
                    <Typography variant="body4">
                      Grade:
                      {isMobile && <br />}
                      <StyledGrade>
                        {displayGrade(course.grade)}
                        {(course.grade === 'B-' || course.grade === 'C-' || course.grade === 'D-')
                          ? <HiddenSpan>minus</HiddenSpan> : ''}
                      </StyledGrade>
                      {(hasSpecialGrades(course.grade)) && (
                        <ToggleTooltip
                          iconWidth={1.8}
                          iconHeight={1.8}
                          iconColor={theme.palette.almostBlack.main}
                          tooltipAriaLabel={tooltipVariations(
                            course.grade,
                            getCourseOutcomeDeadDate(course.membershipId),
                          )}
                          tooltipMessage={tooltipVariations(
                            course.grade,
                            getCourseOutcomeDeadDate(course.membershipId),
                          )}
                          placement="top"
                        />
                      )}
                    </Typography>
                  </GradeContainer>
                  {(hasSpecialGrades(course.grade))
                    && getCourseOutcomeDeadDate(course.membershipId)
                    && (
                      <DueDate variant="body4">
                        {'Due: '}
                        <span>
                          {toDateString(getCourseOutcomeDeadDate(course.membershipId))}
                        </span>
                      </DueDate>
                    )}
                </StyledTableCell>
              </TableRow>
            ))}
          </TableBody>
        </StyledTable>
      </TableContainer>
    </Root>
  );
}
