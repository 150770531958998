import {
  Grid, Box, Typography,
} from '@mui/material';
import IconItem from '@/components/molecules/icon-item';
import { formatPhoneNumberWithParentheses } from '@/myphoenix/utils/phone-functions';
import { useChat } from '@/hooks/useChat';
import config from '@/helpers/config';
import {
  CardBody, Heading, SubHeading, TermHeading, ContactHeading, ContactBox,
  Button, ChatTextLinkButton, Content, BodyBox, StyledLink,
} from './LeftTile.styles';

const { uop: { baseUrl: uopBaseUrl } } = config;
const admissionsUrl = '/application/admissions';

type CardProps = {
  applicationCompleted: boolean,
  headingText: JSX.Element,
  subheadingText: string,
  body: JSX.Element,
  hasTasks: boolean,
};

export type LeftTileType = {
  isCBEDA?: boolean,
  termStartDate?: string,
  termEndDate?: string,
  contactFirstName?: string,
  contactLastName?: string,
  contactPhoneNumber?: string,
  contactEmail?: string,
  showTermInfo?: boolean,
  applicationSubmissionStatus: string,
  completeTasksURL?: string
  hasIncompleteEnrollmentTasks?: boolean,
  hasCompletedEnrollmentTasks?: boolean
};

function Card({ applicationCompleted,
  hasTasks,
  headingText,
  subheadingText,
  body }: CardProps) {
  if (applicationCompleted || hasTasks) {
    return (
      <Content>
        <TermHeading>{headingText}</TermHeading>
        <SubHeading variant="body4" component="h3">{subheadingText}</SubHeading>
        <CardBody component="p" pt={1} variant="body4">
          {hasTasks
            ? 'Let\'s keep moving forward - complete your tasks so you can get scheduled for class. Reach out to your enrollment representative for support when you need it.'
            : 'Your enrollment representative will reach out to you to schedule your first class - contact us if you need support sooner.'}
        </CardBody>
        <BodyBox>{body}</BodyBox>
      </Content>
    );
  }

  if (!applicationCompleted) {
    return (
      <Content>
        <TermHeading>{headingText}</TermHeading>
        <SubHeading variant="body4">You&apos;re doing great!</SubHeading>
        <CardBody component="p" pt={1} variant="body4">
          Your application is almost complete. Let&apos;s finish now so you can keep moving forward!
        </CardBody>
        <BodyBox>{body}</BodyBox>
      </Content>
    );
  }
  return null;
}

function LeftTile({
  showTermInfo,
  applicationSubmissionStatus,
  termStartDate,
  termEndDate,
  isCBEDA = false,
  contactFirstName,
  contactLastName,
  contactPhoneNumber = '602-387-6372',
  contactEmail,
  completeTasksURL = '/message-center.html',
  hasIncompleteEnrollmentTasks = false,
  hasCompletedEnrollmentTasks = false,
}: LeftTileType) {
  const hasTasks = hasIncompleteEnrollmentTasks && !hasCompletedEnrollmentTasks;
  const applicationCompleted = applicationSubmissionStatus === 'COMPLETED';
  const headingText = () => {
    if (showTermInfo) {
      return (
        <>
          <Heading variant="body4">Prepare for your term</Heading>
          {termStartDate && termEndDate && (
            <Heading variant="body4">{`${termStartDate} - ${termEndDate}`}</Heading>
          )}
        </>
      );
    }
    if (applicationCompleted) {
      return (<Heading variant="body4">Prepare for class</Heading>);
    }
    if (!applicationCompleted) {
      return (
        <Heading variant="body4">COMPLETE YOUR APPLICATION</Heading>
      );
    }
    return null;
  };

  const subheadingText = () => {
    if (applicationCompleted) {
      if (hasTasks) {
        return 'You have tasks to complete';
      }
      return isCBEDA ? 'You\'re not currently enrolled in class' : 'We\'re getting your schedule ready.';
    }
    return 'We\'re glad you\'re coming back';
  };

  const startChat = useChat();
  const body = () => {
    const hasContact = contactFirstName && contactLastName && contactPhoneNumber && contactEmail;

    if (applicationCompleted && !hasTasks && hasContact) {
      return (
        <>
          <ContactBox>
            <Grid container alignItems="center" sx={{ marginBottom: '1rem' }}>
              <Grid item xs={12} pb={1}>
                <ContactHeading variant="body4">
                  {contactFirstName}
                  {' '}
                  {contactLastName}
                </ContactHeading>
              </Grid>
              <Grid item xs={12} sm={5}>
                <IconItem
                  text={`Email ${contactFirstName}`}
                  icon="icon-email-mc"
                  url={`mailto:${contactEmail}`}
                  id={`sr_rep_email_ac_${contactFirstName}_${contactLastName}`}
                  type="link"
                />
              </Grid>
              <Grid item xs={12} sm={5} ml={{ xs: 0, sm: 1 }}>
                <IconItem
                  text={formatPhoneNumberWithParentheses(contactPhoneNumber)}
                  icon="icon-support-mc"
                  url={`tel:${contactPhoneNumber}`}
                  id={`sr_rep_phone_ac_${contactFirstName}_${contactLastName}`}
                  type="link"
                />
              </Grid>
            </Grid>

          </ContactBox>
          {' '}
          <Box pt={{ xs: 5, sm: 8 }} pr={{ xs: 0, sm: 1 }}>
            <Button variant="contained" onClick={() => startChat()}>Chat with us</Button>
          </Box>
        </>
      );
    }
    if (hasTasks && hasContact) {
      return (
        <>
          <ContactBox>
            <Grid container alignItems="center">
              <Grid item xs={12} pb={1}>
                <ContactHeading variant="body4">
                  {contactFirstName}
                  {' '}
                  {contactLastName}
                </ContactHeading>
              </Grid>
              <Grid item xs={12} sm={5}>
                <IconItem
                  text={`Email ${contactFirstName}`}
                  icon="icon-email-mc"
                  url={`mailto:${contactEmail}`}
                  id={`sr_rep_email_ac_${contactFirstName}_${contactLastName}`}
                  type="link"
                />
              </Grid>
              <Grid item xs={12} sm={5} ml={{ xs: 0, sm: 1 }}>
                <IconItem
                  text={formatPhoneNumberWithParentheses(contactPhoneNumber)}
                  icon="icon-support-mc"
                  url={`tel:${contactPhoneNumber}`}
                  id={`sr_rep_phone_ac_${contactFirstName}_${contactLastName}`}
                  type="link"
                />
              </Grid>
            </Grid>

          </ContactBox>
          <Box pr={{ xs: 0, sm: 1 }}>
            <Button variant="contained" href={applicationCompleted ? completeTasksURL : `${uopBaseUrl}${admissionsUrl}`}>
              {applicationCompleted ? 'View enrollment tasks' : 'Return to application'}
            </Button>
          </Box>
        </>
      );
    }
    if (!applicationCompleted) {
      return (
        <>
          <Box>
            <Typography variant="body4" component="h4">Need help?</Typography>

            <ChatTextLinkButton disableRipple>
              Chat with us
            </ChatTextLinkButton>
            <Typography component="span" variant="body4"> or call </Typography>
            <StyledLink
              href={`tel:${contactPhoneNumber}`}
              underline="none"
            >
              {formatPhoneNumberWithParentheses(contactPhoneNumber)}
            </StyledLink>

          </Box>
          <Box>
            <Button variant="contained" href={`${uopBaseUrl}${admissionsUrl}`}>
              Return to application
            </Button>
          </Box>
        </>
      );
    }
    return (
      <>
        <ContactBox>
          <Typography mb={1.2} fontWeight={500} variant="body4" display="block">Contact Us</Typography>

          <StyledLink
            href={`tel:${contactPhoneNumber}`}
            underline="none"
          >
            {formatPhoneNumberWithParentheses(contactPhoneNumber)}
          </StyledLink>

        </ContactBox>
        {' '}
        <Box pr={{ xs: 0, sm: 1 }} pt={4}>
          {hasTasks ? <Button variant="contained" href={completeTasksURL}>View enrollment tasks</Button>
            : <Button variant="contained" onClick={() => startChat()}>Chat with us</Button>}
        </Box>
      </>
    );
  };

  return (
    <Card
      applicationCompleted={applicationCompleted}
      hasTasks={hasTasks}
      headingText={headingText()}
      subheadingText={subheadingText()}
      body={body()}
    />
  );
}

export default LeftTile;
