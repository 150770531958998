import AlumniSingleTile from '@/components/molecules/alumni-single-tile';
import { getMostRecentPastCourse } from '@/myphoenix/utils/course-functions';
import { CourseInfoData } from '@/types/courseInfoData';
import { CourseInfoScenarioInterface } from '@/types/courseInfoScenarios';

function AlumniSingleCourseScenario(): CourseInfoScenarioInterface {
  return {
    shouldShow: (courseInfoData: CourseInfoData) => (
      courseInfoData.primaryProgram?.type === 'Program'
      && !courseInfoData.currentCourses?.length
      && !courseInfoData.futureCoursesInNext180Days?.length
      && courseInfoData.pastCourses?.length > 0 && getMostRecentPastCourse(courseInfoData?.pastCourses)?.statusSubCode === 'CO'
      && (courseInfoData.enrollmentStatus?.status === 'W' || courseInfoData.enrollmentStatus?.status === 'F')
    ),
    Component: (
      { courseInfoData: { parentComponentName, primaryProgram, pastCourses } }
      : { courseInfoData: CourseInfoData },
    ) => (
      <AlumniSingleTile
        parentComponentName={parentComponentName}
        studentProgramType={primaryProgram?.type}
        qualificationLevel={primaryProgram?.qualificationLevel}
        primaryProgramName={primaryProgram?.name}
        courseTitle={
          getMostRecentPastCourse(pastCourses)?.title || primaryProgram.name
        }
      />
    ),
  };
}

export default AlumniSingleCourseScenario;
