import UpcomingTile from '@/components/molecules/upcoming-tile';
import ScheduledTile from '@/components/molecules/scheduled-tile';
import makeAbsoluteUrl from '@/helpers/absolute-url';
import { getScheduledCourses, sortByStartDate } from '@/myphoenix/utils/course-functions';
import { diffFromToday } from '@/myphoenix/utils/date-time-functions';
import type { CourseInfoData, Instructor } from '@/types/courseInfoData';
import type { CourseInfoScenarioInterface } from '@/types/courseInfoScenarios';
import { useState } from 'react';

function WithdrawnWithUpcomingCoursesScenario(): CourseInfoScenarioInterface {
  return {
    shouldShow: (courseInfoData: CourseInfoData) => {
      if (courseInfoData?.futureCoursesInNext7Days.length > 0) {
        const firstScheduledCourse = getScheduledCourses(
          sortByStartDate(courseInfoData.futureCoursesInNext7Days),
        )?.[0];
        const daysForNextScheduled = diffFromToday(firstScheduledCourse?.startDate);
        return courseInfoData?.enrollmentStatus?.status?.toUpperCase() === 'W' && firstScheduledCourse && daysForNextScheduled < 181;
      }
      return false;
    },
    Component: (
      {
        courseInfoData: {
          futureCoursesInNext7Days,
          pastCourses,
          instructorsForMemberships,
          primaryProgram: { isCBEDA = false },
        },
      }: { courseInfoData: CourseInfoData },
    ) => {
      const [selectedIndex, setSelectedIndex] = useState(0);

      const scheduledFutureCourses = getScheduledCourses(sortByStartDate(futureCoursesInNext7Days));
      const firstScheduledCourse = scheduledFutureCourses?.[0];

      const scheduledCoursesWithSameStartDate = scheduledFutureCourses.filter(
        (course) => course.startDate === firstScheduledCourse.startDate,
      );
      const membershipIdsForScheduledCourses = scheduledFutureCourses.flatMap(
        (course) => course?.membershipId,
      );

      const instructorsForCourses = membershipIdsForScheduledCourses
        .reduce<{ [key: string]: Instructor }>(
        (acc, membershipId) => (
          instructorsForMemberships[membershipId]
            ? { ...acc, [membershipId]: instructorsForMemberships[membershipId] } : acc
        ),
        {},
      );

      const pastCoursesStartDates = pastCourses.map((pastCourse) => diffFromToday(pastCourse.startDate, 'days'));
      const closestDate = pastCoursesStartDates?.length
        ? Math.max(...pastCoursesStartDates) * -1 : 0;
      const isNewStudent = closestDate <= 455;
      if (scheduledCoursesWithSameStartDate?.length > 1) {
        return (
          <UpcomingTile
            upcomingClasses={scheduledCoursesWithSameStartDate}
            instructors={instructorsForCourses}
            link={makeAbsoluteUrl('/academic-plan.html')}
            isCBEDA={isCBEDA}
            large
            selectedCourseIndex={selectedIndex}
            setSelectedCourseIndex={setSelectedIndex}
          />
        );
      }

      return (
        <ScheduledTile
          courseObject={firstScheduledCourse}
          instructorObject={instructorsForCourses?.[firstScheduledCourse?.membershipId]}
          isCBEDA={isCBEDA}
          isNewStudent={isNewStudent}
          parentComponentName="course-info"
        />
      );
    },
  };
}

export default WithdrawnWithUpcomingCoursesScenario;
