import NearGradTile from '@/components/molecules/near-grad-tile';
import { CourseInfoData } from '@/types/courseInfoData';
import { CourseInfoScenarioInterface } from '@/types/courseInfoScenarios';

function NearGraduatedScenario(): CourseInfoScenarioInterface {
  return {
    shouldShow: ({ currentCourses,
      futureCoursesInNext180Days,
      enrollmentStatus,
      primaryProgramProgress }: CourseInfoData) => currentCourses.length === 0
        && futureCoursesInNext180Days.length === 0 && enrollmentStatus?.status === 'F'
        && primaryProgramProgress?.creditsSummary?.notScheduled === 0,
    Component: (
      { courseInfoData: { parentComponentName, primaryProgram } }
      : { courseInfoData: CourseInfoData },
    ) => (
      <NearGradTile
        parentComponentName={parentComponentName}
        primaryProgramName={primaryProgram.name}
      />
    ),
  };
}

export default NearGraduatedScenario;
