import { getCurrentDate, toDateTime } from '@/myphoenix/utils/date-time-functions';
import { CourseInfoData } from '@/types/courseInfoData';
import configs from '@/helpers/config';

type Status = CourseInfoData['enrollmentStatus'];
type Courses = CourseInfoData['currentCourses'];

export const alumni = (enrollmentStatus: Status) => enrollmentStatus?.status === 'G';

export const isWithdrawn = (enrollmentStatus: Status) => enrollmentStatus.status === 'W';

export const withDrawnDate = (enrollmentStatus: Status) => (
  isWithdrawn(enrollmentStatus) ? enrollmentStatus.statusDate : undefined);

export const loa = (studentStatusData: Status) => studentStatusData?.status === 'A';

export const programCompleted = (enrollmentStatus: Status, currentCourses: Courses, futureCourses: Courses) => enrollmentStatus.status === 'C'
  && !currentCourses.length && !futureCourses.length;

export const isFullTime = (enrollmentStatus: Status) => enrollmentStatus?.status === 'F';

export const newStudent = (enrollmentStatus: Status) => !enrollmentStatus || (enrollmentStatus && enrollmentStatus.status === 'X');

export const isEnrollmentApplicationActive = (applicationStartTime: string) => applicationStartTime
  && (getCurrentDate() < toDateTime(applicationStartTime).plus({ year: 1 }));

export const reEnrollStudent = (enrollmentStatus: Status, admissionStatus: Status, applicationStartTime: string) => (enrollmentStatus && enrollmentStatus.status === 'W')
  && (admissionStatus && admissionStatus.status === 'RE') && applicationStartTime && withDrawnDate
  && (toDateTime(applicationStartTime) > toDateTime(withDrawnDate(enrollmentStatus)))
  && isEnrollmentApplicationActive(applicationStartTime);

export const isReEnrollI = (applicationType: string) => applicationType && applicationType === 'REENROLL-I';

export const alumniApplicationStudent = (
  applicationStartTime: string,
  enrollmentStatus: Status,
) => alumni(enrollmentStatus)
&& applicationStartTime && isEnrollmentApplicationActive(applicationStartTime);

export const enrollmentApplicationSubmitted = (enrollmentSubmissionStatus: string) => enrollmentSubmissionStatus === 'COMPLETED';

export const getEditApplicationLink = (
  applicationVersion: number,
  applicationReviewStatus: string,
) => {
  if (applicationVersion >= 4) {
    // allow editing if review has not started yet
    if (!applicationReviewStatus) {
      return configs.phoenixEdu.baseUrl.concat(configs.phoenixEdu.appCenterPath);
    }
    return null;
  }
  return configs.phoenixEdu.appCenterUrl;
};
