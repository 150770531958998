import { Interval } from 'luxon';
import { getCurrentDate, toDateTime, diffFromToday } from '@/myphoenix/utils/date-time-functions';
import { FeatureVariableKeys } from '@/helpers/features';
import { useVariableValue } from '@devcycle/react-client-sdk';
import Enrollment from '@/components/molecules/cbeda-enrollment';
import Attendance from '@/components/molecules/cbeda-attendance';
import { useGetMembershipsQuery } from '@/store/queries/courses';
import { getPersonIdFromSessionStorage } from '@/helpers/auth';
import { useGetProgramsQuery } from '@/store/queries/programs';
import { sortMemberships } from '@/helpers/filter-courses';

function CBEDAEngagement(): JSX.Element {
  const componentName: string = 'CbeDaEngagement';

  const personId: string = getPersonIdFromSessionStorage();
  const blackOutUntil: string = useVariableValue(FeatureVariableKeys.DaEngagementTileBlackoutEndDate, 'N/A');
  const featureFlagOn = (!blackOutUntil
    || (blackOutUntil !== 'N/A' && diffFromToday(blackOutUntil) <= 0));
  const {
    isError: membershipError,
    isLoading: membershipLoading,
    data: memberships = [],
  } = useGetMembershipsQuery(
    { personId },
    { skip: !featureFlagOn || !personId },
  );
  const {
    isError: programError,
    isLoading: programLoading,
    data: { primaryProgram } = {},
  } = useGetProgramsQuery(
    { personId },
    { skip: !featureFlagOn || !personId },
  );
  const isError: boolean = membershipError || programError;
  const isLoading: boolean = membershipLoading || programLoading;
  const today = getCurrentDate();
  const sortedMembershipCurrentIds = sortMemberships(memberships)?.currentMembershipIds;
  const isCurrentCBEDAMembership = (membership: { sourceId: string, offeringType: string }) => {
    if (sortedMembershipCurrentIds) {
      return sortedMembershipCurrentIds.includes(membership.sourceId) && membership.offeringType === 'DA';
    }
    return false;
  };
  const currentCBEDAMemberships = memberships.filter(
    (membership: { sourceId: string, offeringType: string }) => (
      isCurrentCBEDAMembership(membership)
    ),
  );
  const membershipForTerm = currentCBEDAMemberships[0];
  const currentAcademicTerm = membershipForTerm?.daAcademicYearTerm;
  const isCBEDA = primaryProgram?.cbeDaProgram && primaryProgram.cbeDaProgram === 'TRUE';
  const lastActivityDate: string = primaryProgram?.lastActivityDate;
  if (!featureFlagOn || !isCBEDA || !currentCBEDAMemberships || isLoading || isError) {
    return null;
  }
  const ENROLLMENT_PERIOD_IN_DAYS = 14;
  const DAYS_TO_POST_UNTIL_DROPPED_AFTER_LAST_POST = 28;
  const DAYS_TO_SHOW_ATTENDANCE_ENGAGEMENT_AFTER_NO_POSTS = 14;
  const endOfToday = today.endOf('day');
  const lastPost = toDateTime(lastActivityDate);
  const currentTermStartDate = toDateTime(currentAcademicTerm?.termStartDate);
  const daysSinceTermStartDate = endOfToday.diff(currentTermStartDate, 'days').days;
  const postedToday = lastPost.hasSame(today, 'day');
  const postedInTermRange = Interval.fromDateTimes(currentTermStartDate, endOfToday);
  const postedInTermBeforeToday = postedInTermRange.contains(lastPost);
  const isWithinEnrollmentPeriod = daysSinceTermStartDate < ENROLLMENT_PERIOD_IN_DAYS;
  const enrollmentDaysLeft = Math.ceil(ENROLLMENT_PERIOD_IN_DAYS - daysSinceTermStartDate);
  const daysSinceLastPost = endOfToday.diff(lastPost, 'days').as('days');

  if ((isWithinEnrollmentPeriod && !postedInTermBeforeToday)
      || (isWithinEnrollmentPeriod && postedToday)) {
    return (
      <Enrollment
        parentComponentName={componentName}
        postedToday={postedToday}
        daysLeft={postedToday ? DAYS_TO_POST_UNTIL_DROPPED_AFTER_LAST_POST : enrollmentDaysLeft}
      />
    );
  }

  if ((!isWithinEnrollmentPeriod && postedInTermBeforeToday
        && (daysSinceLastPost >= DAYS_TO_SHOW_ATTENDANCE_ENGAGEMENT_AFTER_NO_POSTS
        && daysSinceLastPost <= DAYS_TO_POST_UNTIL_DROPPED_AFTER_LAST_POST))
        || (!isWithinEnrollmentPeriod && postedToday)) {
    const currentTermEndDate = toDateTime(currentAcademicTerm?.termEndDate);
    const daysUntilEndOfTerm = currentTermEndDate.diff(endOfToday, 'days').as('days');
    const daysLeftToPost = Math.ceil(
      daysUntilEndOfTerm > DAYS_TO_SHOW_ATTENDANCE_ENGAGEMENT_AFTER_NO_POSTS
        ? DAYS_TO_POST_UNTIL_DROPPED_AFTER_LAST_POST - daysSinceLastPost : daysUntilEndOfTerm,
    );
    if (daysLeftToPost > 0) {
      return (
        <Attendance
          parentComponentName={componentName}
          lastPost={lastActivityDate}
          daysLeft={postedToday ? DAYS_TO_POST_UNTIL_DROPPED_AFTER_LAST_POST : daysLeftToPost}
          postedToday={postedToday}
        />
      );
    }
  }
  return null;
}

export default CBEDAEngagement;
