import ReEnrollmentTile from '@/components/molecules/reenrollment-tile';
import features from '@/helpers/features';
import { CourseInfoData } from '@/types/courseInfoData';
import { CourseInfoScenarioInterface } from '@/types/courseInfoScenarios';
import { isReEnrollI, reEnrollStudent } from '@/myphoenix/utils/enrollment-functions';

function ReenrollScenario(): CourseInfoScenarioInterface {
  return {
    shouldShow: (courseInfoData: CourseInfoData) => features?.reenrollEnabled
    && !courseInfoData?.hasIncompleteEnrollmentTasks
    && !courseInfoData?.hasCompletedEnrollmentTasks
    && reEnrollStudent(
      courseInfoData?.enrollmentStatus,
      courseInfoData?.admissionStatus,
      courseInfoData?.enrollmentApp?.startTime,
    )
    && !isReEnrollI(courseInfoData?.enrollmentApp?.type)
    && !courseInfoData?.currentCourses?.length && !courseInfoData?.futureCoursesInNext7Days?.length,
    Component: (
      { courseInfoData: { enrollmentApp } }
      : { courseInfoData: CourseInfoData },
    ) => (
      <ReEnrollmentTile
        enrollmentAppStatus={enrollmentApp?.submissionStatus}
        programName={enrollmentApp?.program?.description}
        applicationId={enrollmentApp?.ID}
        applicationType={enrollmentApp?.type}
      />
    ),
  };
}

export default ReenrollScenario;
