import GenericModal from '@/components/atoms/generic-modal';
import IframeElement from '@/components/atoms/iframe';
import StepsTracker from '@/components/molecules/steps-tracker';
import makeAbsoluteUrl from '@/helpers/absolute-url';
import { useLazyGetSubmittedApplicationQuery } from '@/store/queries/document';
import { Box, CircularProgress, Fade, Typography } from '@mui/material';
import { toDateString } from '@/myphoenix/utils/date-time-functions';
import { useMemo, useState } from 'react';
import { Heading, LoadingContainer, ProgramTitle, StyledLink } from './ApplicationStatusTile.styles';

const COMPLETED = 'COMPLETED';

export type ApplicationStatusTileType = {
  programTitle?: string;
  applicationReviewStatus?: string;
  applicationSubmissionStatus?: string;
  editApplicationLink?: string;
  applicationSubmittedAt?: string;
  hasIncompleteEnrollmentTasks?: boolean;
  hasCompletedEnrollmentTasks?: boolean;
  applicationId?: string;
  applicationType?: string;
};

function makeSteps(
  isApplicationSubmitted: boolean,
  applicationSubmittedAt: string,
  hasIncompleteEnrollmentTasks: boolean,
  hasCompletedEnrollmentTasks: boolean,
) {
  return [
    {
      title: isApplicationSubmitted
        ? `Application submitted on ${toDateString(applicationSubmittedAt, 'LLL d, yyyy')}`
        : 'Submit your application',
      checked: isApplicationSubmitted,
    },
    {
      title: 'Complete your enrollment tasks',
      checked: !hasIncompleteEnrollmentTasks
        && hasCompletedEnrollmentTasks
        && isApplicationSubmitted,
    },
    {
      title: 'Get ready for class',
      checked: false,
    },
  ];
}

function getApplicationStatus(isApplicationSubmitted: boolean, isApplicationUnderReview: boolean) {
  if (!isApplicationSubmitted) return 'Application not yet submitted';
  if (isApplicationUnderReview) return 'Application under review';
  return 'Application review completed';
}

function ApplicationStatusTile({
  programTitle,
  applicationReviewStatus = COMPLETED,
  applicationSubmissionStatus = COMPLETED,
  editApplicationLink = '',
  applicationSubmittedAt = '',
  hasIncompleteEnrollmentTasks = false,
  hasCompletedEnrollmentTasks = false,
  applicationId = '',
  applicationType = null,
}: ApplicationStatusTileType) {
  // Application submitted is the same as completed.
  const isApplicationSubmitted = applicationSubmissionStatus === COMPLETED;
  const isApplicationUnderReview = applicationReviewStatus !== COMPLETED;

  const steps = useMemo(() => makeSteps(
    isApplicationSubmitted,
    applicationSubmittedAt,
    hasIncompleteEnrollmentTasks,
    hasCompletedEnrollmentTasks,
  ), [
    isApplicationSubmitted,
    applicationSubmittedAt,
    hasIncompleteEnrollmentTasks,
    hasCompletedEnrollmentTasks,
  ]);

  const applicationStatusText = useMemo(
    () => getApplicationStatus(isApplicationSubmitted, isApplicationUnderReview),
    [isApplicationSubmitted, isApplicationUnderReview],
  );

  const [isSubmittedApplicationOpen, setIsSubmittedApplicationOpen] = useState(false);
  const [
    loadApplicationTrigger,
    { data, isLoading, isFetching, isSuccess, isError },
  ] = useLazyGetSubmittedApplicationQuery();

  const openModalAndLoadData = () => {
    setIsSubmittedApplicationOpen(true);
    loadApplicationTrigger({ applicationId, applicationType }, true);
  };

  return (
    <>
      <Heading variant="body4" component="h2">Application Status</Heading>
      {programTitle && (<ProgramTitle variant="body4" component="h3">{programTitle}</ProgramTitle>)}
      <Typography
        variant="body4"
        component="p"
        sx={{ mb: 2, color: isApplicationSubmitted && !isApplicationUnderReview ? 'donut.systemGreen' : 'primary.main' }}
      >
        {applicationStatusText}
      </Typography>
      <Box mb={5}>
        <StepsTracker orientation="vertical" steps={steps} />
      </Box>
      {isApplicationSubmitted && (
        <Box>
          {editApplicationLink && (
            <div>
              <StyledLink href={makeAbsoluteUrl(editApplicationLink)}>
                Edit your application
              </StyledLink>
            </div>
          )}
          <div>
            <StyledLink component="button" role="button" onClick={openModalAndLoadData}>
              View submitted application
            </StyledLink>
          </div>
        </Box>
      )}
      <GenericModal
        open={isSubmittedApplicationOpen}
        toggleModal={setIsSubmittedApplicationOpen}
        maxWidth="md"
      >
        {(isLoading || isFetching) && (
          <LoadingContainer>
            <Fade in timeout={500} style={{ transitionDelay: '500ms' }}>
              <CircularProgress size={150} />
            </Fade>
          </LoadingContainer>
        )}
        {isSuccess && data && (
          <IframeElement content={data} frameProps={{ title: 'View submitted application' }} styleProps={{ maxWidth: '100%' }} />
        )}
        {isError && (
          <Typography variant="body4">
            Sorry, we could not find your application. Please, try again later.
          </Typography>
        )}
      </GenericModal>
    </>
  );
}
export default ApplicationStatusTile;
