import { Grid, Theme, useMediaQuery, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDisplayEventTracking } from '@/hooks/useDisplayEventTracking';
import { useClickEventTracking } from '@/hooks/useClickEventTracking';
import { componentNameToMyPhxKebabFormat } from '@/helpers/string-utils';
import { getItem, setItem } from '@/helpers/session-storage';
import Icon from '@/components/atoms/icon';
import ArrowLink from '@/components/atoms/arrow-link';
import { useGetAudiencesQuery } from '@/store/queries/segment';
import { getBBClassUrl, getBBRootUrl } from '@/myphoenix/utils/blackboard-functions';
import { useGetCoursesBySourceIdsQuery, useGetMembershipsQuery } from '@/store/queries/courses';
import { getPersonIdFromSessionStorage } from '@/helpers/auth';
import { sortMemberships } from '@/helpers/filter-courses';
import {
  StyledBox,
  StyledIcon,
  AlertGrid,
  StyledHeading,
  StyledIconButton,
  MessageGridItem,
  MessageTypography,
  ArrowLinkGridItem,
} from './InlineAlert.styles';

type DeviceType = 'mobile' | 'tablet' | 'desktop';

type SeverityType = 'error' | 'warning' | 'info' | 'success' | null;

type Alert = {
  headingMessage?: string,
  message?: string,
  severity?: SeverityType,
  link?: string,
  linkLabel?: string,
  isDismissable?: boolean,
  deviceType?: DeviceType,
  audience?: string,
};

type AudienceDeviceAlert = {
  audience: string,
  mobile: Alert,
  tablet: Alert,
  desktop: Alert,
};

const getIcon = (severity: SeverityType) => {
  switch (severity) {
    case 'warning':
      return 'icon-alert-sharp';
    case 'success':
      return 'icon-green-circle-check';
    default:
      return 'icon-info-solid';
  }
};

const getColor = (severity: SeverityType, theme: Theme) => {
  switch (severity) {
    case 'error':
      return theme.palette.primary.d20;
    case 'warning':
      return theme.palette.custom.warnOrange;
    default:
      return theme.palette.custom.infoBlue;
  }
};

const getAlertByDevice = (
  deviceType: DeviceType,
  audienceDeviceAlert?: AudienceDeviceAlert,
): Alert => {
  let alert: Alert = null;
  if (audienceDeviceAlert) {
    if (deviceType === 'mobile') {
      alert = audienceDeviceAlert.mobile;
    } else if (deviceType === 'tablet') {
      alert = audienceDeviceAlert.tablet;
    } else {
      alert = audienceDeviceAlert.desktop;
    }
    alert.deviceType = deviceType;
    alert.audience = audienceDeviceAlert.audience;
  }
  return alert || {
  };
};

const getAlertByAudienceByDevice = (
  audiences?: string[],
  deviceType?: DeviceType,
  audienceDeviceAlerts?: AudienceDeviceAlert[],
): Alert | null => {
  if (audiences && deviceType && audienceDeviceAlerts) {
    const matchingAudienceDeviceAlert: AudienceDeviceAlert = audienceDeviceAlerts.find(
      (audienceDeviceAlert) => (
        audiences.includes(audienceDeviceAlert.audience)
        && (getAlertByDevice(deviceType, audienceDeviceAlert).message)
      ),
    );
    return getAlertByDevice(deviceType, matchingAudienceDeviceAlert);
  }
  return null;
};

const getDeviceType = (
  isMobile: boolean,
  isTablet: boolean,
) => {
  if (isMobile) {
    return 'mobile';
  } if (isTablet) {
    return 'tablet';
  }
  return 'desktop';
};

const lateAssignmentAudience :Alert = {
  headingMessage: "There's still time to earn valuable points",
  message: "Complete your assignment now to stay on track. Reach out for help if you need it, we're here for you.",
  severity: 'info',
  isDismissable: true,
  link: getBBRootUrl(),
  linkLabel: 'Go to class',
};

function InlineAlertBox({ audiences, audienceDeviceAlerts =
[{
  audience: 'tech_nudge_mobile',
  mobile: {
    headingMessage: 'Try using your computer',
    message: 'Students who do their assignments on their computers are twice as likely to succeed in class.',
    severity: 'info',
    isDismissable: true,
  },
  tablet: {
  },
  desktop: {
  },
}, {
  audience: 'has_late_assignment_worth_points',
  mobile: lateAssignmentAudience,
  tablet: lateAssignmentAudience,
  desktop: lateAssignmentAudience,

}] }: { audiences?: string[], audienceDeviceAlerts?: AudienceDeviceAlert[] }) {
  const componentName = 'InlineAlertBox';
  const trackDisplay = useDisplayEventTracking();
  const trackClick = useClickEventTracking();
  const [dismissToggle, setDismissToggle] = useState(false);
  const DISMISSED_SESSION_ID_PREFIX = 'myphoenix-inlinealert-dismissed';
  const wasAlreadyDismissed = (audience: string) => !!getItem(`${DISMISSED_SESSION_ID_PREFIX}-${audience}`);
  const dismiss = (audience:string) => {
    setDismissToggle(!dismissToggle);
    setItem(`${DISMISSED_SESSION_ID_PREFIX}-${audience}`, true);
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'lg'));
  const deviceType = getDeviceType(isMobile, isTablet);
  const personId = getPersonIdFromSessionStorage();

  const {
    data: audiencesFromSegment = [],
    isLoading: audiencesLoading,
  } = useGetAudiencesQuery({ personId }, { skip: Array.isArray(audiences) });

  const {
    data: memberships = [],
  } = useGetMembershipsQuery({ personId });

  const {
    data: courses = [],
  } = useGetCoursesBySourceIdsQuery({
    sourceIds: memberships.map((membership: { sourceId: string }) => membership.sourceId),
  });

  const notDroppedMemberships = memberships.filter((membership = { statusSubCode: '' }) => membership.statusSubCode !== 'DR' && membership.statusSubCode !== 'TA');
  const sortedMembershipIds = sortMemberships(notDroppedMemberships);
  const currentCourse = sortedMembershipIds.currentMembershipIds
    .map((membershipId) => courses
      .find((course: { id: string }) => {
        if (course.id === membershipId) {
          return membershipId;
        } return false;
      }))[0];

  const alertAudiences = audiences || (!audiencesLoading && audiencesFromSegment);
  const alert = getAlertByAudienceByDevice(alertAudiences, deviceType, audienceDeviceAlerts);

  useEffect(() => {
    if (alert?.message && !wasAlreadyDismissed(alert.audience)) {
      trackDisplay(
        componentName,
        [
          { audienceName: alert.audience },
          { label: alert.audience },
          { device: alert.deviceType },
        ],
      );
    }
  }, [trackDisplay, alert?.audience, alert?.deviceType, alert?.message]);

  if (!(alert && alert.message) || wasAlreadyDismissed(alert.audience)) {
    return null;
  }

  const icon = getIcon(alert.severity);
  const severityColor = getColor(alert.severity, theme);

  const onCloseClick = () => {
    dismiss(alert.audience);
    trackClick(
      `${componentName}_Close`,
      [
        { audienceName: alert.audience },
        { label: alert.audience },
        { device: alert.deviceType },
      ],
    );
  };

  // Due to time constraints we didnt seperate
  // the data from the UI. Whole thing is pending a refactor

  const getAlertLink = alert?.audience === 'has_late_assignment_worth_points'
   && currentCourse?.templateCode ? getBBClassUrl(currentCourse?.templateCode) : alert?.link;

  return (
    <StyledBox
      data-component={componentNameToMyPhxKebabFormat(componentName)}
      $severity={severityColor}
    >
      <StyledIcon
        id={`${alert.severity}-icon`}
        icon={icon}
        color={severityColor}
      />
      <AlertGrid
        container
        direction="column"
        spacing={0}
      >
        <Grid item>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
          >
            <Grid item>
              <StyledHeading variant="body4">
                {alert.headingMessage}
              </StyledHeading>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid
            container
            direction={{ xs: 'column', md: 'row' }}
            justifyContent={{ xs: 'flex-start', md: 'space-between' }}
            wrap="nowrap"
            spacing={0}
          >
            <MessageGridItem item $isDismissable={alert.isDismissable}>
              <MessageTypography variant="body5">
                {alert.message}
              </MessageTypography>
            </MessageGridItem>
            {alert.link
              && ((alert.severity === 'info' || alert.severity === 'success') ? (
                <ArrowLinkGridItem item>
                  <ArrowLink
                    link={getAlertLink}
                    cta={false}
                    clickEvent={{
                      componentName,
                      properties: [
                        { audienceName: alert.audience },
                        { label: alert.audience },
                        { device: alert.deviceType },
                        { link: getAlertLink },
                        { text: alert.linkLabel },
                      ],
                    }}
                  >
                    {alert.linkLabel}
                  </ArrowLink>
                </ArrowLinkGridItem>
              ) : (
                <ArrowLinkGridItem item>
                  <ArrowLink
                    link={getAlertLink}
                    clickEvent={{
                      componentName,
                      properties: [
                        { audienceName: alert.audience },
                        { label: alert.audience },
                        { device: alert.deviceType },
                        { link: getAlertLink },
                        { text: alert.linkLabel },
                      ],
                    }}
                  >
                    {alert.linkLabel}
                  </ArrowLink>
                </ArrowLinkGridItem>
              ))}
          </Grid>
        </Grid>
      </AlertGrid>
      {alert.isDismissable && (
        <StyledIconButton
          onClick={onCloseClick}
          aria-label="Close this alert"
        >
          <Icon
            id="icon-close"
            icon="icon-close"
          />
        </StyledIconButton>
      )}
    </StyledBox>
  );
}

export default InlineAlertBox;
