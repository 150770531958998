import { Grid, Typography, Box, Divider, useMediaQuery, Theme } from '@mui/material';
import ClassSkills from '@/components/molecules/class-skills';
import IconItem from '@/components/molecules/icon-item';
import SelectionDropdown from '@/components/molecules/selection-dropdown';
import { getClassroomClassUrl, getClassroomKey } from '@/myphoenix/utils/classroom-functions';
import { toDateTime, diffFromToday } from '@/myphoenix/utils/date-time-functions';
import { CourseIcon, Button, CodeLink, StatusText, SmallText, CodeLinkList, ButtonGrid } from './CourseList.styles';
import type {
  ListCourseListType,
  CourseListType,
  SingleCourseListType,
  MultipleCourseListType,
} from './CourseList.types';

// HELPER FUNCTIONS
function ClassIsDropped(statusSubcode: string) {
  return statusSubcode === 'TA';
}

function getCourseStartEndDates(
  course: { [item: string]: any },
  srText?: boolean,
) {
  const timezone = course.type === 'CL' ? 'local' : undefined;
  const startDateMoment = toDateTime(course.startDate, timezone);
  const endDateMoment = toDateTime(course.endDate, timezone);
  let startDate;
  if (startDateMoment.year === endDateMoment.year) {
    startDate = startDateMoment.toFormat('MMM dd');
  } else {
    startDate = startDateMoment.toFormat('DD');
  }
  const endDate = endDateMoment.toFormat('DD');

  if (srText) {
    return `${startDate} to ${endDate}`;
  }
  return `${startDate} - ${endDate}`;
}

function getSkills(
  courseSkills: { [key: string]: { [item: string]: any }[] },
  courseTemplateCode: string,
) {
  return courseSkills[courseTemplateCode]?.map(
    (info: { [item: string]: any }) => info?.skill,
  );
}

function getLink(
  course: { [item: string]: any },
  isCBEDA: boolean,
) {
  const { sectionId } = course;
  const startDateFromToday = diffFromToday(course.startDate);
  return startDateFromToday >= 7 ? '/academic-plan.html' : getClassroomClassUrl(getClassroomKey({ isCBEDA }), { sectionId });
}

// SUBCOMPONENTS
function Single({
  courses,
  instructors,
  userHasSkillsClass,
  courseSkills,
  isCBEDA,
  primaryButtonText,
}: SingleCourseListType) {
  const skills = getSkills(courseSkills, courses[0]?.templateCode);
  return (
    <>
      <Grid container direction={{ xs: 'column', sm: 'row' }}>
        <Grid item>
          <CourseIcon icon="icon-class-mc" id="icon-class-mc" />
        </Grid>
        <Grid item xs={8}>
          <Box pl={1} pt={1.5}>
            <Typography variant="h5" component="h3" fontWeight={700}>{courses[0]?.title}</Typography>
            <CodeLink
              underline="none"
              fontWeight={500}
              href={getLink(courses[0], isCBEDA)}
            >
              {courses[0]?.templateCode}
            </CodeLink>
            {ClassIsDropped(courses[0]?.statusSubCode) && <StatusText variant="body3">Class dropped</StatusText>}
            {userHasSkillsClass && skills?.length > 0 && (
              <Box pt={4} pb={1}>
                <ClassSkills
                  skills={skills}
                  tooltipMessage="Skills are earned as you make progress and take assessments through your class. Skills are separate from your course grade."
                />
              </Box>
            )}
            {instructors[courses[0]?.membershipId]
              && (
                <Box py={2}>
                  <IconItem
                    text={`${instructors[courses[0]?.membershipId]?.firstName} ${instructors[courses[0]?.membershipId]?.lastName}`}
                    icon="icon-contact"
                    id="icon-contact"
                    type="text"
                    srText={`Faculty ${instructors[courses[0]?.membershipId]?.firstName} ${instructors[courses[0]?.membershipId]?.lastName}`}
                  />
                </Box>
              )}
            <IconItem
              text={getCourseStartEndDates(courses[0])}
              icon="icon-calendar-mc"
              id="icon-calendar-mc"
              type="text"
              srText={`Course dates ${getCourseStartEndDates(courses[0], true)}`}
            />
          </Box>
        </Grid>
      </Grid>
      <ButtonGrid container>
        <Grid item pt={2} xs={12}>
          <Button href={getLink(courses[0], isCBEDA)}>{primaryButtonText}</Button>
        </Grid>
      </ButtonGrid>
    </>
  );
}

function List({ courses, instructors, isCBEDA, showFacultyNamesAndDates }: ListCourseListType) {
  return (
    <Grid container direction={{ xs: 'column', sm: 'row' }} minHeight={80}>
      {courses.map((course, index) => (
        <Grid item xs={8} key={course?.title}>
          {index > 0 && <Divider />}
          <Box mb={1.6} mt={0.6}>
            <CodeLinkList underline="none" fontWeight={500} href={getLink(course, isCBEDA)}>{course?.templateCode}</CodeLinkList>
            <Box>
              <Typography
                variant="body4"
                fontSize={{ xs: '2.4rem', sm: '1.6rem' }}
                display={{ xs: 'block', sm: 'inline-block' }}
                fontWeight={700}
                lineHeight={1.4}
                component="h3"
              >
                {course?.title}

              </Typography>
              {ClassIsDropped(course?.statusSubCode) && <SmallText variant="body4">(Class dropped)</SmallText>}
            </Box>
            {showFacultyNamesAndDates && (
              <>
                {
                  instructors[course?.membershipId] && (
                    <Box pt={2}>
                      <IconItem
                        text={`${instructors[course?.membershipId]?.firstName} ${instructors[course?.membershipId]?.lastName}`}
                        icon="icon-contact"
                        id="icon-contact"
                        type="text"
                        srText={`Faculty ${instructors[course?.membershipId]?.firstName} ${instructors[course?.membershipId]?.lastName}`}
                      />
                    </Box>
                  )
                }
                <Box pt={2}>
                  <IconItem
                    text={getCourseStartEndDates(course)}
                    icon="icon-calendar-mc"
                    id="icon-calendar-mc"
                    type="text"
                    srText={`Course dates ${getCourseStartEndDates(course, true)}`}
                  />
                </Box>
              </>
            )}
          </Box>
        </Grid>
      ))}
    </Grid>
  );
}

function Multiple({
  courses = [],
  instructors,
  userHasSkillsClass,
  courseSkills,
  fullWidth,
  isCBEDA,
  selectedCourseIndex,
  setSelectedCourseIndex,
  primaryButtonText,
}: MultipleCourseListType) {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const currentCourse = courses[selectedCourseIndex];
  return (
    <>
      <Grid container direction="row" maxWidth={fullWidth ? null : '60rem'}>
        <Grid item xs={12} sm={8}>
          <Box pt={1}>
            <SelectionDropdown
              optionsList={courses.map((e, index) => ({
                value: `${e.title}-${index}`,
                title: e.title,
                ariaLabel: e.ariaLabel,
              }))}
              selectedIndex={selectedCourseIndex}
              selectedCallback={(value) => {
                const getValueIndex = courses.map((course, index) => `${course.title}-${index}`).indexOf(value);
                setSelectedCourseIndex(getValueIndex);
              }}
            />
            <CodeLink
              underline="none"
              fontWeight={500}
              my={2}
              href={getLink(currentCourse, isCBEDA)}
            >
              {currentCourse?.templateCode}
            </CodeLink>
            {ClassIsDropped(currentCourse?.statusSubCode) && <StatusText variant="body3">Class dropped</StatusText>}
            {userHasSkillsClass && courseSkills[currentCourse?.templateCode]?.length > 0 && (
              <Box py={2}>
                <ClassSkills
                  skills={getSkills(courseSkills, currentCourse?.templateCode)}
                  tooltipMessage="Skills are earned as you make progress and take assessments through your class. Skills are separate from your course grade."
                />
              </Box>
            )}
            {
              instructors[currentCourse.membershipId] && (
                <IconItem
                  text={`${instructors[currentCourse.membershipId]?.firstName} ${instructors[currentCourse.membershipId]?.lastName}`}
                  icon="icon-contact"
                  id="icon-contact"
                  type="text"
                  srText={`Faculty ${instructors[currentCourse.membershipId]?.firstName} ${instructors[currentCourse.membershipId]?.lastName}`}
                />
              )
            }
            <Box py={2}>
              <IconItem
                text={getCourseStartEndDates(currentCourse)}
                icon="icon-calendar-mc"
                id="icon-calendar-mc"
                type="text"
                srText={`Course dates ${getCourseStartEndDates(currentCourse, true)}`}
              />
            </Box>
            {(!fullWidth || isMobile) && (
              <Box pt={2}>
                <Button href={getLink(currentCourse, isCBEDA)}>{primaryButtonText}</Button>
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>

      <ButtonGrid container>
        {
          fullWidth && !isMobile && (
            <Grid item xs={12} pt={2}>
              <Button href={getLink(currentCourse, isCBEDA)}>{primaryButtonText}</Button>
            </Grid>
          )
        }
      </ButtonGrid>

    </>
  );
}

// MAIN COMPONENT
function CourseList({
  courses = [],
  list = false,
  large = false,
  instructors = {},
  userHasSkillsClass = false,
  courseSkills = {},
  fullWidth = false,
  isCBEDA = false,
  selectedCourseIndex = 0,
  setSelectedCourseIndex,
  primaryButtonText = 'Go to Class',
}: CourseListType) {
  if (list) {
    return (
      <List
        courses={courses}
        instructors={instructors}
        isCBEDA={isCBEDA}
        showFacultyNamesAndDates={large}
      />
    );
  }
  if (courses.length > 1) {
    return (
      <Multiple
        courses={courses}
        instructors={instructors}
        userHasSkillsClass={userHasSkillsClass}
        courseSkills={courseSkills}
        selectedCourseIndex={selectedCourseIndex}
        setSelectedCourseIndex={setSelectedCourseIndex}
        fullWidth={fullWidth}
        isCBEDA={isCBEDA}
        primaryButtonText={primaryButtonText}
      />
    );
  }
  return (
    <Single
      courses={courses}
      userHasSkillsClass={userHasSkillsClass}
      courseSkills={courseSkills}
      instructors={instructors}
      isCBEDA={isCBEDA}
      primaryButtonText={primaryButtonText}
    />
  );
}

export default CourseList;
