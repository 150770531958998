import { PropsWithChildren } from 'react';
import {
  Breakpoint,
  useMediaQuery,
  useTheme,
  Dialog,
} from '@mui/material';
import {
  Root,
  CloseButton,
  CloseButtonLabel,
  TopBar,
  StyledIcon,
  PrintButton,
} from './GenericModal.styles';

export type Props = PropsWithChildren<{
  open?: boolean,
  toggleModal: (value: boolean) => void,
  ariaLabelledBy?: string,
  ariaDescribedBy?: string,
  maxWidth?: Breakpoint,
  printable?: boolean,
}>;

function GenericModal(props: Props) {
  const {
    open = false,
    toggleModal,
    ariaLabelledBy = '',
    ariaDescribedBy = '',
    children,
    maxWidth = 'lg',
    printable = false,
  } = props;

  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down('sm'));

  return (
    <Dialog
      id="generic-modal"
      open={open}
      fullScreen={isMobile}
      role="dialog"
      aria-labelledby={ariaLabelledBy}
      aria-describedby={ariaDescribedBy}
      maxWidth={maxWidth}
      onClose={() => toggleModal(false)}
      fullWidth
    >
      <Root>
        <TopBar>
          {printable && (
            <PrintButton
              disableRipple
              aria-label="Print document"
              onClick={window.print}
            >
              <StyledIcon id="icon-print" icon="icon-print" />
            </PrintButton>
          )}
          <CloseButton
            variant="text"
            disableRipple
            onClick={() => toggleModal(false)}
          >
            <CloseButtonLabel>
              Close
            </CloseButtonLabel>
          </CloseButton>
        </TopBar>
        {children}
      </Root>
    </Dialog>
  );
}

export default GenericModal;
