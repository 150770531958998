import ReEnrollmentTile from '@/components/molecules/reenrollment-tile';
import features from '@/helpers/features';
import { CourseInfoData } from '@/types/courseInfoData';
import { CourseInfoScenarioInterface } from '@/types/courseInfoScenarios';
import { isReEnrollI } from '@/myphoenix/utils/enrollment-functions';

function ReenrollIScenario(): CourseInfoScenarioInterface {
  return {
    shouldShow: (courseInfoData: CourseInfoData) => features?.reenrollIEnabled
    && !courseInfoData?.hasIncompleteEnrollmentTasks
    && !courseInfoData?.hasCompletedEnrollmentTasks
    && isReEnrollI(courseInfoData.enrollmentApp.type)
    && !courseInfoData?.currentCourses?.length && !courseInfoData?.futureCoursesInNext7Days?.length,
    Component: (
      { courseInfoData: { enrollmentApp } }
      : { courseInfoData: CourseInfoData },
    ) => (
      <ReEnrollmentTile
        enrollmentAppStatus={enrollmentApp?.submissionStatus}
        programName={enrollmentApp?.program?.description}
        applicationId={enrollmentApp?.ID}
        applicationType={enrollmentApp?.type}
      />
    ),
  };
}

export default ReenrollIScenario;
