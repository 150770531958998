import RescheduleSingleTile from '@/components/molecules/reschedule-tile';
import { CourseInfoData } from '@/types/courseInfoData';
import { CourseInfoScenarioInterface } from '@/types/courseInfoScenarios';
import { getCurrentDate, toDateTime } from '@/myphoenix/utils/date-time-functions';

function RescheduleScenario(): CourseInfoScenarioInterface {
  return {
    shouldShow: (courseInfoData: CourseInfoData) => courseInfoData
      .futureCoursesInNext7Days.length === 0
      && courseInfoData.currentCourses.length === 0
      && !!courseInfoData.pastCourses.find(
        (pastCourse) => toDateTime(pastCourse.startDate)
          > getCurrentDate().plus({ days: -455 }),
      ),
    Component({ courseInfoData }: { courseInfoData: CourseInfoData }) {
      const {
        primaryProgram: { type },
        enrollmentRep: {
          phoneNumber: erPhone,
          firstName: erFirstName,
          lastName: erLastName,
        },
        academicCounselor: {
          firstName: acFirstName,
          lastName: acLastName,
          phoneNumber: acPhone,
        },
        parentComponentName,
      } = courseInfoData;
      let counselorName = '';
      let phone = '';
      if (type === 'Program') {
        if (erFirstName && erLastName) {
          counselorName = `${erFirstName} ${erLastName}`;
          phone = erPhone;
        }
      } else if (acFirstName && acLastName) {
        counselorName = `${acFirstName} ${acLastName}`;
        phone = acPhone;
      }

      return (
        <RescheduleSingleTile
          parentComponentName={parentComponentName}
          counselor={counselorName || undefined}
          phone={phone || undefined}
        />
      );
    },
  };
}

export default RescheduleScenario;
