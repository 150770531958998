import { getPersonIdFromSessionStorage } from '@/helpers/auth';
import ResourceTile from '@/components/molecules/resource-tile';
import { Grid } from '@mui/material';
import useGetMembershipsAndCourses from '@/hooks/useGetMembershipsAndCourses';
import { sortMemberships } from '@/helpers/filter-courses';
import { useGetProgramProgressDataByProgramIdQuery, useGetProgramsQuery } from '@/store/queries/programs';
import { componentNameToMyPhxKebabFormat } from '@/helpers/string-utils';
import { BigTile, LilTile } from './ResourceTile.styles';

function ResourceTiles() {
  const personId = getPersonIdFromSessionStorage();
  const componentName = 'AlumniResourceTiles';
  const { data: { primaryProgram } = {} } = useGetProgramsQuery({ personId });
  const {
    memberships,
    courses,
    isFetching: isGetMembershipAndCoursesFetching,
  } = useGetMembershipsAndCourses(personId);
  const { data: { auditDetails = [], programProgress: { creditsSummary = {} } = {} } = {},
    isLoading: statusProgramProgress } = useGetProgramProgressDataByProgramIdQuery({
    programId: primaryProgram?.id,
  }, { skip: !primaryProgram?.id });

  const isLoading = isGetMembershipAndCoursesFetching || statusProgramProgress;

  const sortedMembershipIds = sortMemberships(memberships);
  const currentCourses = sortedMembershipIds.currentMembershipIds.map((
    membershipId,
  ) => courses.find((course: { id: string }) => course.id === membershipId));
  const futureCourses = sortedMembershipIds.futureMembershipIds.map((
    membershipId,
  ) => courses.find((course: { id: string }) => course.id === membershipId));
  const enrollmentStatus = primaryProgram?.statuses ? primaryProgram.statuses.find((status: { statusType: string }) => (status.statusType === 'EnrollmentStatus')) : null;
  const programCompleted = enrollmentStatus && enrollmentStatus.status === 'C'
    && !currentCourses.length && !futureCourses.length;
  const finishedCourses = !!auditDetails && enrollmentStatus && enrollmentStatus.status === 'F'
    && !currentCourses.length && !futureCourses.length && !isLoading
    && creditsSummary?.notScheduled === 0;
  const alumni = enrollmentStatus && enrollmentStatus.status === 'G';

  let threeTiles = false;
  if (programCompleted || finishedCourses) {
    threeTiles = true;
  }

  const careerResourcesTile = (
    <LilTile>
      <ResourceTile
        header="Career Resources"
        description="Take a moment now to update your resume and start preparing for your career."
        links={[{ label: 'Resume builder', href: 'https://phoenix.peoplegrove.com/hub/phoenix/resources/resume-and-cover-letter' }]}
        size="lil"
      />
    </LilTile>

  );
  const alumniResourcesTile = (
    <BigTile>
      <ResourceTile
        header="Alumni Resources"
        description="You’re almost an alum! Get ready to join your vibrant alumni community for career support and special tuition rates."
        links={[{ label: 'Alumni Benefits', href: 'https://www.phoenix.edu/alumni/benefits-discounts.html' }, { label: 'Alumni Association', href: 'https://www.phoenix.edu/alumni.html' }]}
        size="big"
      />
    </BigTile>
  );
  const weRiseTile = (
    <LilTile>
      <ResourceTile
        header="We Rise"
        description="You're a Phoenix! Show off your accomplishment with a diploma frame."
        links={[{ label: 'Go to Phoenix Store', href: 'https://www.mypromomall.com/phoenixstore?_gl=1*1fzr3q8*_ga*NDk3NDE2ODE1LjE2MTA2NjA1MTQ.*_ga_2LNTX19Q2Z*MTYxMjQ1NTExOC4zLjAuMTYxMjQ1NTEzNC40NA' }]}
        size="lil"
      />
    </LilTile>
  );

  if (!alumni && !programCompleted && !finishedCourses) {
    return null;
  }

  return (
    <Grid data-testid="resource_tile" container data-component={componentNameToMyPhxKebabFormat(componentName)}>

      <Grid
        item
        sm={12}
        md={6}
        pr={1}
      >
        {threeTiles ? alumniResourcesTile : weRiseTile}
      </Grid>
      <Grid
        item
        sm={12}
        md={6}
      >
        {threeTiles && weRiseTile}
        {careerResourcesTile}
      </Grid>
    </Grid>
  );
}

export default ResourceTiles;
