import LinkTile from '@/components/atoms/link-tile';
import { Grid } from '@mui/material';
import { componentNameToMyPhxKebabFormat } from '@/helpers/string-utils';

function LinkTileSixPack() {
  return (
    <Grid
      data-component={componentNameToMyPhxKebabFormat('LinkTileSixPack')}
      container
      rowSpacing={{ xs: 2, sm: 4 }}
      columnSpacing={1}
      columns={{ xs: 1, sm: 3 }}
      width="sm"
    >
      <Grid item xs={1}>
        <LinkTile icon="icon-apple-book" title="Academic Plan" descriptor="Program & schedule info" link="/academic-plan.html" />
      </Grid>
      <Grid item xs={1}>
        <LinkTile icon="icon-money" title="Financial Plan" descriptor="Account & financial info" link="https://ecampus.phoenix.edu/financialplan/" />
      </Grid>
      <Grid item xs={1}>
        <LinkTile icon="icon-support-mc" title="Help & Support" descriptor="Faculty & advisor support" link="/university-contacts.html" />
      </Grid>
      <Grid item xs={1}>
        <LinkTile icon="icon-library-mc" title="University Library" descriptor="Learning resources & tools" link="https://library.phoenix.edu/" />
      </Grid>
      <Grid item xs={1}>
        <LinkTile icon="icon-virtual-student-union" title="Virtual Student Union" descriptor="Student services & support" link="/vsu.html" />
      </Grid>
      <Grid item xs={1}>
        <LinkTile icon="icon-phoenix-link-careers" title="Career Services" descriptor={'Career services for life\u2122'} link="/vsu/career-services.html" />
      </Grid>
    </Grid>
  );
}

export default LinkTileSixPack;
