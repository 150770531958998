import { DateTime } from 'luxon';
import SingleTile from '@/components/molecules/single-tile';
import ToggleTooltip from '@/components/atoms/tooltip';
import ErrorMessage from '@/components/atoms/error-message';
import { componentNameToMyPhxKebabFormat } from '@/helpers/string-utils';
import { FeatureVariableKeys } from '@/helpers/features';
import { useVariableValue } from '@devcycle/react-client-sdk';
import { getPersonIdFromSessionStorage } from '@/helpers/auth';
import { useGetProgramsQuery } from '@/store/queries/programs';
import { useGetCoursesByMembershipsQuery } from '@/store/queries/courses';
import { useGetCBEDataByPersonIdQuery } from '@/store/queries/student';
import { getCurrentCourses } from '@/myphoenix/utils/course-functions';
import type { Course } from '@/types/courseInfoData';
import ArrowLink from '@/components/atoms/arrow-link';
import { Typography } from '@mui/material';
import { getCurrentDate, toDateTime, diffFromToday } from '@/myphoenix/utils/date-time-functions';
import { Attendance, useGetAttendancesHook } from '@/store/hooks/useGetAttendancesHook';
import { CardComponent,
  CardContentRoot,
  HeadingWrapperDiv,
  TypographyHeading,
  BoxToolTip,
  BoxCardContent,
  BoxTextContent,
  TypographyCountdownText,
  DangerTextLarge,
  StyledLink,
  CTAWrapper,
  PostedTodayTitle,
  StyledTitle,
  StyledTypography,
  StyledBoldTypography,
  DangerTextParagraph } from './engagementCBE.styles';

function DangerMessage({
  componentName,
  daysLeft,
  link,
}:{
  componentName: string,
  daysLeft: number,
  link: string
}) {
  return (
    <>
      <DangerTextLarge>
        Today is your last day to post.
      </DangerTextLarge>
      <DangerTextParagraph
        variant="body4"
        variantMapping={{ body4: 'p' }}
      >
        Post in at least one of your classes before midnight tonight,
        {' '}
        <StyledLink href="/kb/article/Arizona-Clock-in-Blackboard" rel="noreferrer">Phoenix, Arizona time</StyledLink>
        {' '}
        to meet your weekly engagement requirement.
        {' '}
      </DangerTextParagraph>
      <Typography
        variant="body4"
        component="b"
      >
        You&apos;ll be automatically dropped from all your courses if you don&apos;t post today.
      </Typography>
      <CTAWrapper>
        <ArrowLink
          link={link}
          clickEvent={{ componentName, properties: [{ daysLeft }] }}
        >
          Post now
        </ArrowLink>
      </CTAWrapper>
    </>
  );
}

function AttendanceMessage({
  daysLeft,
  postedToday,
  isFirstWeek,
  daysSinceLastPost,
}: { daysLeft: number, postedToday: boolean, isFirstWeek: boolean, daysSinceLastPost: number }) {
  const day = daysSinceLastPost === 1 ? 'day' : 'days';
  if (daysLeft > 1 && daysLeft <= 3 && !isFirstWeek && !postedToday) {
    return (
      <StyledBoldTypography
        variant="body4"
      >
        {`Time is running out! Your last post was ${daysSinceLastPost} ${day} ago.`}
      </StyledBoldTypography>
    );
  } if (daysLeft >= 4 && daysLeft <= 7 && !postedToday && !isFirstWeek) {
    return (
      <StyledBoldTypography
        variant="body4"
      >
        {`Your last post was ${daysSinceLastPost} ${day} ago.`}
      </StyledBoldTypography>
    );
  } if (!postedToday && !isFirstWeek) {
    return (
      <StyledBoldTypography
        variant="body4"
      >
        {`Keep up the good work! Your last post was ${daysSinceLastPost} ${day} ago.`}
      </StyledBoldTypography>
    );
  }
  return null;
}

function Engagement() {
  const componentName = 'Engagement';
  const link = 'https://vle.phoenix.edu';
  const errorMessage = 'We’re sorry! We experienced an error retrieving this data. Please refresh your browser.';
  const errorLinkText = 'Reload';
  const toolTipMessage = 'Do one of the following to maintain engagement: Respond within a progress check-in discussion, submit a competency assessment, a competency reflection, or a pacing guide.';

  const personId = getPersonIdFromSessionStorage();

  const blackOutUntil: string = useVariableValue(FeatureVariableKeys.CbeEngagementTileBlackoutEndDate, 'N/A');
  const featureFlagOn = (!blackOutUntil
    || (blackOutUntil !== 'N/A' && diffFromToday(blackOutUntil) <= 0));

  const {
    isError: courseError,
    isLoading: courseLoading,
    data: {
      courses = [],
    } = {},
  } = useGetCoursesByMembershipsQuery(
    { personId },
    { skip: !featureFlagOn || !personId },
  ) as { isError: boolean, isLoading: boolean, data: { courses: Course[] } };

  const {
    isError: programError,
    isLoading: programLoading,
    data: { primaryProgram } = {},
  } = useGetProgramsQuery(
    { personId },
    { skip: !featureFlagOn || !personId },
  );

  const programCode = primaryProgram?.code;
  const programVersion = primaryProgram?.version;

  const currentCourses = getCurrentCourses(courses);
  const currentCBECourses = currentCourses.filter(
    (course: { type: string, statusSubCode: string }) => course.type === 'CB' && (course.statusSubCode !== 'TA' && course.statusSubCode !== 'WI'
    && course.statusSubCode !== 'WL' && course.statusSubCode !== 'WP' && course.statusSubCode !== 'DR'),
  );

  const { isError: attendanceError,
    isFetching: attendanceLoading,
    data: { attendances } } = useGetAttendancesHook(currentCBECourses);
  const { isError: cbeError,
    isLoading: cbeLoading,
    data: { lastARADate: lastPost, estimatedDropDate } = {} } = useGetCBEDataByPersonIdQuery(
    { personId, programCode, programVersion },
    { skip: !featureFlagOn || !personId || !programCode || !programVersion },
  );
  const error: boolean = courseError || programError || cbeError || attendanceError;
  const loading: boolean = courseLoading || programLoading || cbeLoading || attendanceLoading;
  const isCBE = primaryProgram?.cbeProgram === 'TRUE';
  const today = getCurrentDate().endOf('day');
  const formattedDropDate = toDateTime(estimatedDropDate).minus({ days: 1 })
    .toLocaleString(DateTime.DATE_FULL);
  const postedToday: boolean = toDateTime(lastPost).hasSame(today, 'day');
  const daysLeft = Math.ceil(toDateTime(estimatedDropDate).diff(today, 'days').as('days'));
  const startDate = toDateTime(currentCBECourses[0]?.startDate);
  const isFirstWeek = today.diff(startDate, 'days').days < 7;
  const daysSinceLastPost = today.diff(toDateTime(lastPost).endOf('day'), 'days').days;

  const showEngagementTile = () => {
    if (!featureFlagOn) {
      return false;
    }
    // Lists the attendance's membershipIds to cross-check with existing
    // CBE course's membershipIds and then saves into 'existingAtt'
    // which will be used to display (or not) the engagementCBE container
    const existingAtt: string[] = [];
    // For each CBE Course, check if there is attendance data
    // then, extract that data, iterate through and save it to existingAtt[]
    let latestEndDate = '';
    if (currentCBECourses.length > 0) {
      latestEndDate = currentCBECourses[0].endDate;
      currentCBECourses.forEach((course) => {
        attendances?.byMembershipId[course?.membershipId]?.attendances?.forEach(
          (att: Attendance) => {
            existingAtt.push(att.attendance);
          },
        );
        if (toDateTime(course.endDate) > toDateTime(latestEndDate)) {
          latestEndDate = course.endDate;
        }
      });
    }
    return isCBE && ((existingAtt.length > 0)
      && (existingAtt.some((value) => value === 'Y' || value === 'E')
      && existingAtt.every((value) => value === 'Y' || value === 'N' || value === 'E')))
      && (toDateTime(estimatedDropDate) < toDateTime(latestEndDate));
  };

  const isInDangerZone = (daysLeft < 4 && !postedToday);

  if (!showEngagementTile() || daysLeft === 0) {
    return null;
  }

  if (loading) {
    return (
      <SingleTile
        data-testid="cbe_engagement-loading"
        parentComponentName={componentName}
        loading
        darkMode={false}
      />
    );
  }

  return (
    <CardComponent
      data-testid="cbe_engagement"
      $dangerzone={isInDangerZone || undefined}
      data-component={componentNameToMyPhxKebabFormat(componentName)}
    >
      {error ? (
        <ErrorMessage
          errorMessage={errorMessage}
          errorLinkText={errorLinkText}
          errorComponent={componentName}
          aria-label="Engagement Error"
        />
      ) : (
        <CardContentRoot>
          <HeadingWrapperDiv>
            <TypographyHeading variant="body5">
              Engagement
            </TypographyHeading>
            <BoxToolTip>
              <ToggleTooltip
                placement="top"
                tooltipMessage={toolTipMessage}
                tooltipAriaLabel={`tooltip ${Engagement}`}
              />
            </BoxToolTip>
          </HeadingWrapperDiv>
          {daysLeft === 1
            ? (
              <DangerMessage
                componentName={componentName}
                daysLeft={daysLeft}
                link={link}
              />
            ) : (
              <BoxCardContent>
                <BoxTextContent>
                  {postedToday
                    ? (
                      <PostedTodayTitle variant="h6">
                        Great work! You’ve met your engagement requirement
                      </PostedTodayTitle>
                    ) : (
                      <StyledTitle
                        variant="h6"
                        $daysLeft={daysLeft}
                      >
                        Post by
                        {' '}
                        {formattedDropDate}
                        {' '}
                        to meet your engagement requirement
                      </StyledTitle>
                    )}
                  <TypographyCountdownText
                    variant="body4"
                    id="engagement-text"
                    aria-label="Attendance message"
                  >
                    <AttendanceMessage
                      postedToday={postedToday}
                      isFirstWeek={isFirstWeek}
                      daysSinceLastPost={daysSinceLastPost}
                      daysLeft={daysLeft}
                    />
                    <StyledTypography
                      variant="body4"
                    >
                      Continue posting in at least one of your classes every 14 days to avoid
                      being dropped from all your courses.
                    </StyledTypography>
                  </TypographyCountdownText>
                  <ArrowLink
                    ariaLabel="Go to your classes"
                    link={link}
                    clickEvent={{ componentName, properties: [{ daysLeft }] }}
                  >
                    Go to your classes
                  </ArrowLink>
                </BoxTextContent>
              </BoxCardContent>
            )}
        </CardContentRoot>
      )}
    </CardComponent>
  );
}
export default Engagement;
