import DroppedSingleTile from '@/components/molecules/dropped-tile';
import { getDroppedCourses, getEnrolledCourses } from '@/myphoenix/utils/course-functions';
import type { CourseInfoData } from '@/types/courseInfoData';
import type { CourseInfoScenarioInterface } from '@/types/courseInfoScenarios';

const PROGRAM_TYPE = 'program';
const getFullName = (person?: { firstName?: string, lastName?: string }) => (
  person?.firstName && person?.lastName
    ? `${person.firstName} ${person.lastName}`.trim()
    : undefined
);

function DroppedScenario(): CourseInfoScenarioInterface {
  return {
    shouldShow: ({ currentCourses }: CourseInfoData) => (
      !!getDroppedCourses(currentCourses).length && !getEnrolledCourses(currentCourses).length
    ),
    Component({ courseInfoData }: { courseInfoData: CourseInfoData }) {
      const {
        parentComponentName,
        futureCoursesInNext7Days,
        currentCourses,
        primaryProgram: { name: programName, type: programType },
        enrollmentRep,
        academicCounselor,
      } = courseInfoData;

      const droppedCurrentCourses = getDroppedCourses(currentCourses);

      const counselorName = programType?.toLowerCase() === PROGRAM_TYPE
        ? getFullName(enrollmentRep) : getFullName(academicCounselor);
      const counselorPhone = programType?.toLowerCase() === PROGRAM_TYPE
        ? enrollmentRep?.phoneNumber : academicCounselor?.phoneNumber;

      return (
        <DroppedSingleTile
          parentComponentName={parentComponentName}
          small={!!droppedCurrentCourses.length && !!futureCoursesInNext7Days.length}
          courses={currentCourses}
          counselor={counselorName || null}
          phone={counselorPhone}
          primaryProgramName={programName}
        />
      );
    },
  };
}

export default DroppedScenario;
