import { useGetCoursesBySourceIdsQuery, useGetMembershipsQuery } from '@/store/queries/courses';

function useGetMembershipsAndCourses(personId: string) {
  const {
    data: memberships = [],
    isFetching: isGetMembershipsFetching,
  } = useGetMembershipsQuery({ personId });
  const { data: courses = [], isFetching: isGetCoursesFetching } = useGetCoursesBySourceIdsQuery({
    sourceIds: memberships?.map(
      (membership: { sourceId: string }) => membership.sourceId,
    ),
  }, { skip: isGetMembershipsFetching });

  return {
    memberships,
    courses,
    isFetching: isGetCoursesFetching || isGetMembershipsFetching,
  };
}

export default useGetMembershipsAndCourses;
